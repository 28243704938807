import moment from 'moment';
import { environment } from '../../environments/environment';

export function createSignMessage(address: string, chainId: number): string {
  const domain = environment.auth.domain;
  const statement = 'Validação da sua carteira para o Marketplace';
  const uri = window.location.href;
  const nonce = Math.floor(Math.random() * 1000).toString(16).substring(0, 4);
  const issuedAt = moment();
  const expirationTime = issuedAt.clone().add(environment.auth.expiryMinutes, 'minutes');

  return `${domain} wants you to sign in with your Ethereum account:
${address}

${statement}

URI: ${uri}
Version: 1
Chain ID: ${chainId}
Nonce: ${nonce}
Issued At: ${issuedAt.toISOString()}
Expiration Time: ${expirationTime.toISOString()}`;
}
