import { ReactElement, AnchorHTMLAttributes, useMemo } from 'react';
import { findChain } from '../../services/chains';
import { formatAddress } from '../../utils/functions';

export interface AddressLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  address: string;
  chainId: number;
}

export function AddressLink({ address, chainId, children, ...innerProps }: AddressLinkProps): ReactElement {
  const href = useMemo(
    () => findChain(chainId)?.explorerAddressUrl?.replace('{address}', address),
    [address, chainId],
  );

  const content = useMemo(
    () => children || formatAddress(address),
    [children, address]
  );

  return (
    <a {...innerProps} href={href} title={address} target="_blank" rel="noreferrer noopener">
      {content}
    </a>
  );
}
