import * as baseAxios from 'axios';
import { CancelToken } from 'axios';

import useAuthStore from '../store/useAuth';
import { environment } from '../environments/environment';

export const publicApi = baseAxios.default.create({
  baseURL: environment.api.baseUrl,
  timeout: 30 * 1_000,
});

export const api = baseAxios.default.create({
  baseURL: environment.api.baseUrl,
  timeout: 30 * 1_000,
});

api.interceptors.request.use(async (config) => {
  const token = await useAuthStore.getState().getToken();

  if (!token) return config;

  config.headers.common['Authorization'] = token.token;

  return config;
});

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Promise<T> {
    cancel?: () => void;
  }
}

export function cancellableRequest<T>(
  factoryPromise: (cancelToken: CancelToken) => Promise<T> & { cancel?: () => void },
): Promise<T> & { cancel?: () => void } {
  const source = baseAxios.default.CancelToken.source();
  const promise = factoryPromise(source.token);

  promise.cancel = () => {
    console.log('Cancelado...');

    source.cancel('A operação não foi concluída, a requisição foi cancelada.');
  };

  return promise;
}

