import { ReactElement, useCallback, useEffect, useState } from 'react';
import { NftMetadataAttributeStruct } from '../../models/structs/nft-metadata-attribute.struct';
import { isNumber } from '../../utils/functions';
import { OfLabel, PropertyLine, RemoveButton, TitleInput, ValueInput } from './styles';
import moment from 'moment';
import { environment } from '../../environments/environment';

export interface PropertyInputProps {
  type: 'text' | 'date' | 'number';
  value: NftMetadataAttributeStruct;
  onChange: (value: NftMetadataAttributeStruct) => void;
  onRemove: () => void;
}

export function PropertyInput({ value, type, onChange, onRemove }: PropertyInputProps): ReactElement {
  const [title, setTitle] = useState<string>('');
  const [val, setVal] = useState<string>('');
  const [maxVal, setMaxVal] = useState<string>('');

  const onBlur = useCallback(() => {
    const isText = type === 'text';
    const isDate = type === 'date';
    const isRange = type === 'number';

    const attribute: NftMetadataAttributeStruct = {
      trait_type: title?.trim(),
      value: val?.trim(),
    };

    if (isRange || (isText && isNumber(val))) {
      attribute.value = parseFloat(val);
      attribute.display_type = 'number';
    }

    if (isRange) {
      attribute.max_value = parseFloat(maxVal);
    }

    if (isDate) {
      attribute.value = moment(val).unix();
      attribute.display_type = 'date';
    }

    onChange(attribute);
  }, [onChange, type, title, val, maxVal]);

  useEffect(() => {
    setTitle(value.trait_type || '');
    setMaxVal(value.max_value?.toString() || '');

    if (type === 'date' && typeof value.value === 'number')
      setVal(moment.unix(value.value).format('YYYY-MM-DD'));
    else
      setVal(value.value?.toString() || '');

  }, [type, value]);

  return (
    <PropertyLine>
      <RemoveButton onClick={onRemove}>X</RemoveButton>

      <TitleInput type="text" value={title} onChange={e => setTitle(e.target.value)}
                  maxLength={environment.limits.maxPropertyTitle}
                  placeholder="Título" onBlur={onBlur} />

      <ValueInput type={type} value={val} onChange={e => setVal(e.target.value)}
                  maxLength={environment.limits.maxPropertyValue}
                  placeholder="Valor" onBlur={onBlur} />

      {type === 'number' && (
        <>
          <OfLabel>De</OfLabel>
          <ValueInput type={type} value={maxVal} onChange={e => setMaxVal(e.target.value)}
                      maxLength={environment.limits.maxPropertyValue}
                      placeholder="Máximo" onBlur={onBlur} />
        </>
      )}
    </PropertyLine>
  );
}
