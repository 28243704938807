import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import {
  PropertyAddButton,
  PropertyItem, PropertyItemTitle, PropertyItemValue, Select,
  UploadImageContainer,
  UploadImageItem, UploadImageLabel,
  UploadImageNothing
} from './styles';
import { Label, LabelDescription, LabelDiv, LabelText } from '../../../components/Label';
import imageIcon from '../../../assets/images/image.svg';
import Modal from 'antd/lib/modal/Modal';
import { EditPropertyModal } from '../../../components/EditPropertyModal';
import { NftMetadataAttributeStruct } from '../../../models/structs/nft-metadata-attribute.struct';
import { formatAttribute, formatAttributeValue } from '../../../utils/metadata';
import { getCollections } from '../../../services/collections';
import useWeb3 from '../../../store/useWeb3';
import { CollectionProxy } from '../../../models/proxies/collection.proxy';
import { createErrorNotification } from '../../../services/notification';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';

export interface UploadImageInputProps {
  label: string;
  image: File;
  onChange: (image: File) => void;
}

export function UploadImageInput(props: UploadImageInputProps): ReactElement {
  const fileRef = useRef<HTMLInputElement>();
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (!props.image)
      return setImage(undefined);

    const url = URL.createObjectURL(props.image);

    setImage(url);

    return () => URL.revokeObjectURL(url);
  }, [setImage, props.image])

  function onClick() {
    fileRef.current?.click();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files.length === 0)
      return;

    const file = event.target.files[0];

    props.onChange(file);
  }

  return (
    <UploadImageLabel>
      <LabelText>{props.label}</LabelText>
      <UploadImageContainer onClick={onClick}>
        {image ?
          <UploadImageItem src={image} alt="Imagem da NFT" />
          :
          <UploadImageNothing>
            <img src={imageIcon} alt="Ícone" />
          </UploadImageNothing>
        }

        <input type="file" ref={fileRef} onChange={onChange} />
      </UploadImageContainer>
    </UploadImageLabel>
  );
}

export interface PropertiesInputProps {
  type: 'text' | 'date' | 'number';
  label: string;
  description: string;
  value: NftMetadataAttributeStruct[];
  onChange: (value: NftMetadataAttributeStruct[]) => void;
}

export function PropertiesInput({ type, label, description, value, onChange }: PropertiesInputProps): ReactElement {
  const [isOpen, setOpen] = useState<boolean>(false);

  function onSave(data: NftMetadataAttributeStruct[]) {
    setOpen(false);
    onChange(data);
  }

  function onAdd(e: React.MouseEvent) {
    e.preventDefault();
    setOpen(true);
  }

  return (
    <LabelDiv>
      <LabelText>{label}</LabelText>
      <LabelDescription>{description}</LabelDescription>

      {value?.map((attrib, i) =>
        <PropertyItem key={i}>
          <PropertyItemTitle>{attrib.trait_type}</PropertyItemTitle>
          <PropertyItemValue>{formatAttribute(attrib)}</PropertyItemValue>
        </PropertyItem>
      )}

      <PropertyAddButton onClick={onAdd}>Adicionar propriedade</PropertyAddButton>

      <Modal visible={isOpen}
             onCancel={() => setOpen(false)}
             modalRender={() => <EditPropertyModal type={type} attributes={value || []} onSave={onSave} />} />
    </LabelDiv>
  );
}

export interface CollectionInputProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  onCreate: () => void;
}

export function CollectionInput({ label, value, onChange, onCreate }: CollectionInputProps): ReactElement {
  const address = useWeb3(s => s.address);
  const [collections, setCollections] = useState<CollectionProxy[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!address)
      return;

    setLoading(true);

    getCollections({
      fields: ['title', 'id'],
      search: { creatorAddress: address.toLowerCase() },
      sort: [{ field: 'title', order: 'ASC' }],
    })
      .then(result => {
        setCollections(result.data);

        if (result.data.length > 0)
          onChange(result.data[0].id);
      })
      .catch(error => createErrorNotification(error))
      .finally(() => setLoading(false));
  }, [onChange, address]);

  function onChangeSelect(e: React.ChangeEvent<HTMLSelectElement>): void {
    const value = e.target.value;

    if (value === 'create-new')
      onCreate();
    else
      onChange(value ? +value : undefined);
  }

  return (
    <Label>
      <LabelText>{label}</LabelText>

      <Spin spinning={isLoading}>
        <Select value={value} onChange={onChangeSelect}>
          <option></option>

          {collections?.map((collection, i) =>
            <option key={i} value={collection.id}>{collection.title}</option>
          )}

          <option value="create-new">Criar uma nova</option>
        </Select>
      </Spin>
    </Label>
  )
}

