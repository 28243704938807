import styled from '@emotion/styled';

export const Container = styled.div<{ hasLove: boolean }>`
  background: rgba(255, 255, 255, ${props => props.hasLove ? 1 : 0.75});
  border-radius: 100%;
  width: 3em;
  height: 3em;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  user-select: none;
`;

export const Icon = styled.img`
  width: 1.5em;
  height: 1.5em;
  object-fit: contain;
`;
