import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;

export const CurrencySelect = styled.select`
  
`;
