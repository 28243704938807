import React, { ReactElement, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Header } from './components/Header';
import { DiscoverPage } from './pages/DiscoverPage';
import { NftMinePage } from './pages/NFTPage/MinePage';
import { NftCreatePage } from './pages/NFTPage/CreatePage';
import { LandingPage } from './pages/LandingPage';
import { Footer } from './components/Footer';
import { NftViewPage } from './pages/NFTPage/ViewPage';
import { CollectionListPage } from './pages/CollectionPage/ListPage';
import { CollectionEditPage } from './pages/CollectionPage/EditPage';
import { CollectionMinePage } from './pages/CollectionPage/MinePage';
import { CollectionViewPage } from './pages/CollectionPage/ViewPage';

function AppRouting(): ReactElement | null {
  return (
    <Suspense fallback={<p>Carregando...</p>}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>

          <Route exact path="/discover">
            <DiscoverPage />
          </Route>

          <Redirect from="/token/:address/:id" to="/tokens/ethereum/:address/:id" exact />
          <Redirect from="/tokens/:address/:id" to="/tokens/ethereum/:address/:id" exact />

          <Route path="/tokens/:chainSlug/:address/:id">
            <NftViewPage />
          </Route>

          <Route exact path="/me/tokens/create">
            <NftCreatePage />
          </Route>

          <Route exact path="/me/tokens">
            <NftMinePage />
          </Route>

          <Route exact path="/collections">
            <CollectionListPage />
          </Route>

          <Route exact path="/collections/:id">
            <CollectionViewPage />
          </Route>

          <Route exact path="/collections/:id/edit">
            <CollectionEditPage />
          </Route>

          <Route exact path="/me/collections/create">
            <CollectionEditPage />
          </Route>

          <Route exact path="/me/collections">
            <CollectionMinePage />
          </Route>

          <Route default>
            <Redirect to="/"/>
          </Route>
        </Switch>
        <Footer />
     </BrowserRouter>
    </Suspense>
  );
}

export default AppRouting;
