import React, { ReactElement, ReactNode, useState } from 'react';
import { Box, Title, Dict, Table, Value, Content, Container } from './styles';

export interface DetailsProps {
  title?: string;
  children: ReactNode;
}

export function Details(props: DetailsProps): ReactElement {
  const [isClosed, setClosed] = useState<boolean>(false);

  return (
    <Box>
      {props.title &&
        <Title onClick={() => setClosed(closed => !closed)} closed={isClosed}>{props.title}</Title>
      }

      <Container closed={isClosed}>
        {props.children}
      </Container>
    </Box>
  );
}

export function DetailsDictItem(props: { label: string, title?: string, children: ReactNode }): ReactElement {
  return (
    <Dict>
      <span>{props.label}</span>
      <Value title={props.title}>{props.children}</Value>
    </Dict>
  );
}

export function DetailsTable(props: { children: ReactNode }): ReactElement {
  return (
    <Table>
      {props.children}
    </Table>
  );
}

export function DetailsContent(props: React.HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <Content {...props}>
      {props.children}
    </Content>
  );
}
