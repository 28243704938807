import styled from '@emotion/styled';

export const Button = styled.button`
  background: #F7941D;
  color: #fff;
  border-radius: 0.5em;
  border: 0;
  padding: 1em 1.25em;
  font-size: 1rem;
  min-width: 8em;
  
  user-select: none;
  
  &:disabled {
    background: #BFBFBF;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const InputButton = styled.input`
`;

export const SecondaryButton = styled.button`
  background: #fff;
  color: #F7941D;
  border-radius: 0.5rem;
  border: 1px solid #F7941D;
  padding: 1em 1.25em;
  font-size: 1rem;

  user-select: none;

  &:disabled {
    opacity: 0.75;
  }
`;
