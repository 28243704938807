import { IpfsFileProxy } from '../models/proxies/ipfs-file.proxy';
import { api } from './api';

/**
 * Realiza o upload de um arquivo para o IPFS
 *
 * @param file O arquivo
 */
export async function uploadToIpfs(file: File): Promise<IpfsFileProxy> {
  const form = new FormData();

  form.set('file', file);

  const { data } = await api.post<IpfsFileProxy>('/ipfs/upload/file', form);

  return data;
}

/**
 * Realiza o upload de um JSON para o IPFS
 *
 * @param payload O JSON
 */
export async function uploadJsonToIpfs<T>(payload: T): Promise<IpfsFileProxy> {
  const { data } = await api.post<IpfsFileProxy>('/ipfs/upload/json', payload);

  return data;
}
