import styled from '@emotion/styled';
import { Button } from '../Button';

export const ModalContainer = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  min-width: 30rem;
  max-width: 90vw;
  padding: 1rem;
`;

export const CreateButton = styled(Button)`
  width: 100%;
`;
