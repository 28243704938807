import { ReactElement, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';

import { PageTitle } from '../../../components/PageTitle';
import { Button } from '../../../components/Button';
import { ContentContainer } from '../../../components/ContentContainer';
import { ButtonContainer, Container } from './styles';
import { CollectionInput, PropertiesInput, UploadImageInput } from './inputs';
import { createNft } from '../../../services/nft';
import { createErrorNotification, createSuccessNotification } from '../../../services/notification';
import { CreateNftPayload } from '../../../models/payloads/create-nft.payload';
import { TextAreaInput, TextInput } from '../../../components/TextInput';
import { NftMetadataAttributeStruct } from '../../../models/structs/nft-metadata-attribute.struct';
import { findChain } from '../../../services/chains';
import { environment } from '../../../environments/environment';

export function NftCreatePage(): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [royalties, setRoyalties] = useState<string>('0');
  const [image, setImage] = useState<File>();
  const [collection, setCollection] = useState<number>();

  const [textAttribs, setTextAttribs] = useState<NftMetadataAttributeStruct[]>([]);
  const [numberAttribs, setNumberAttribs] = useState<NftMetadataAttributeStruct[]>([]);
  const [dateAttribs, setDateAttribs] = useState<NftMetadataAttributeStruct[]>([]);

  const history = useHistory();

  function onSubmit(e: MouseEvent) {
    e.preventDefault();

    setLoading(true);

    const payload: CreateNftPayload = {
      name,
      description,
      image,
      collection,
      royaltiesPercentage: parseFloat(royalties || '0') / 100,
      attributes: [
        ...textAttribs,
        ...numberAttribs,
        ...dateAttribs,
      ],
    };

    createNft(payload)
      .then((token) => {
        createSuccessNotification('NFT criada com sucesso!');
        const chain = findChain(token.contractChainId);

        history.push(`/tokens/${chain.slug}/${encodeURIComponent(token.contractAddress)}/${encodeURIComponent(token.id)}`);
      })
      .catch(err => createErrorNotification(err))
      .finally(() => setLoading(false));
  }

  return (
    <ContentContainer>
      <Spin spinning={loading}>
        <PageTitle center>Criar NFT</PageTitle>
        <Container>
          <UploadImageInput label="Foto do item" image={image} onChange={setImage} />
          <div>
            <CollectionInput label="Coleção" value={collection} onChange={setCollection}
                             onCreate={() => history.push('/me/collections/create')} />

            <TextInput label="Nome do item" maxLength={environment.limits.maxNftTitle} required
                       value={name} onChange={setName} />

            <TextAreaInput label="Descrição do item" maxLength={environment.limits.maxNftDescription}
                           value={description} onChange={setDescription} />

            <TextInput label="Royalties (%)" type="number" min={0} max={15} step={0.1} value={royalties} onChange={setRoyalties}
                       description="O percentual que você irá receber de todas as vendas desse item" required />

            <PropertiesInput
              type="text" label="Propriedades" value={textAttribs} onChange={setTextAttribs}
              description="Propriedades em forma de texto que serão adicionadas no metadados do NFT e exibidos em retângulos nos detalhes."
            />

            <PropertiesInput
              type="number" label="Estatísticas" value={numberAttribs} onChange={setNumberAttribs}
              description="Propriedades numéricas que serão adicionadas no metadados do NFT e exibidos nos detalhes."
            />

            <PropertiesInput
              type="date" label="Datas" value={dateAttribs} onChange={setDateAttribs}
              description="Datas que serão adicionadas no metadados do NFT e exibidos nos detalhes."
            />

            <ButtonContainer>
              <Button onClick={onSubmit}>Mint!</Button>
            </ButtonContainer>
          </div>
        </Container>
      </Spin>
    </ContentContainer>
  );
}

