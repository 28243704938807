import { ReactElement, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { PageTitle } from '../../../components/PageTitle';
import { NFTGrid } from '../../../components/NFTGrid';
import { createErrorNotification } from '../../../services/notification';
import { ContentContainer } from '../../../components/ContentContainer';
import { CollectionCard } from '../../../components/CollectionCard';
import { getCollections } from '../../../services/collections';
import { CollectionProxy } from '../../../models/proxies/collection.proxy';
import { GetManyDefaultResponseProxy } from '../../../models/proxies/base/get-many-default-response.proxy';
import { EmptyMessage } from '../../../components/EmptyMessage';

const placeholderCollections: GetManyDefaultResponseProxy<CollectionProxy> = {
  page: 0,
  count: 0,
  total: 0,
  pageCount: 0,
  data: new Array<CollectionProxy>(16).fill({
    id: 0,
    slug: '',
    description: '',
    title: '',
    pictureUrl: '',
    creatorAddress: '',
    isPlaceholder: true,
  }),
};

export function CollectionListPage(): ReactElement {
  const [page, setPage] = useState<number>(1);
  const [collections, setCollections] = useState<GetManyDefaultResponseProxy<CollectionProxy>>(placeholderCollections);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    getCollections({ page })
      .then(data => setCollections(data))
      .catch(err => createErrorNotification(err.message))
      .finally(() => setLoading(false));
  }, [page]);

  return (
    <ContentContainer>
      <PageTitle center>Explore Coleções</PageTitle>
      <Spin spinning={isLoading}>
        {!isLoading && collections?.total === 0 && (
          <EmptyMessage>Nenhuma coleção encontrada!</EmptyMessage>
        )}
        <NFTGrid>
          {collections?.data.map((collection, i) =>
            <CollectionCard collection={collection} key={i} />
          )}
        </NFTGrid>
      </Spin>
    </ContentContainer>
  );
}
