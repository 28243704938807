import { ReactElement, AnchorHTMLAttributes, useMemo } from 'react';
import { findChain } from '../../services/chains';
import { getUserName } from '../../utils/users';
import { UserProxy } from '../../models/proxies/user.proxy';

export interface UserLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  address: string;
  user?: UserProxy;
  chainId: number;
}

export function UserLink({ address, user, chainId, children, ...innerProps }: UserLinkProps): ReactElement {
  const href = useMemo(
    () => findChain(chainId)?.explorerAddressUrl?.replace('{address}', address),
    [address, chainId],
  );

  const content = useMemo(
    () => children || getUserName(address, user),
    [children, address, user]
  );

  return (
    <a {...innerProps} href={href} title={address} target="_blank" rel="noreferrer noopener">
      {content}
    </a>
  );
}
