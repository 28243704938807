import styled from '@emotion/styled';
import { Button } from '../../../../components/Button';

export const MethodSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
  border: 0.05rem solid #8C8C8C;
  border-radius: 1rem;
  
  margin: 1rem 0;
`;

export const Method = styled.button`
  display: block;
  padding: 1.5rem 1rem;
  
  font-weight: 500;
  background: transparent;
  border-radius: 1rem;
  border: 0.05rem solid transparent;
  margin: -0.05rem;

  box-shadow: 0 0 0 0 transparent;
  
  &.active {
    background: #fff;
    border: 0.05rem solid #0094FF;
    box-shadow: 0 0 7px 4px rgba(0, 194, 255, 0.25);
    transition: box-shadow 0.2s linear;
  }
`;

export const Rates = styled.div`
  margin: 1rem 0;
`;

export const SellButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 1.25rem;
  font-weight: 700;
`;
