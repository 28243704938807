import { BigNumber, ethers } from 'ethers';
import { environment } from '../environments/environment';

export interface BlockchainInfo {
  chainId: number;
  name: string;
  slug?: string;
  explorerAddressUrl?: string;
  explorerTransactionUrl?: string;
  ensAddress?: string;
  testnet?: boolean;

  contracts?: {
    nft: string,
    market: string,
  };

  currencies?: BlockchainCurrency[];
}

export interface BlockchainCurrency {
  address: string;
  name: string;
  symbol: 'ETH' | 'WETH' | string;
}

const chains: BlockchainInfo[] = [
  {
    chainId: 1,
    name: 'Ethereum',
    slug: 'ethereum',
    explorerAddressUrl: 'https://etherscan.io/address/{address}',
    explorerTransactionUrl: 'https://etherscan.io/tx/{hash}',
    testnet: false,
  },
  {
    chainId: 3,
    name: 'Ropsten',
    slug: 'ropsten',
    explorerAddressUrl: 'https://ropsten.etherscan.io/address/{address}',
    explorerTransactionUrl: 'https://ropsten.etherscan.io/tx/{hash}',
    testnet: true,
  },
  {
    chainId: 4,
    name: 'Rinkeby',
    slug: 'rinkeby',
    explorerAddressUrl: 'https://rinkeby.etherscan.io/address/{address}',
    explorerTransactionUrl: 'https://rinkeby.etherscan.io/tx/{hash}',
    testnet: true,
    contracts: {
      nft: '0xe48E98F5A52a0985a89d35D9421CE79c49916599',
      market: '0xf810de33a5A78080353620E032dd464cF69648B3',
    },
    currencies: [
      {
        address: ethers.constants.AddressZero,
        name: 'Rinkeby Ether',
        symbol: 'ETH',
      },
      {
        address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
        name: 'Wrapped Ether',
        symbol: 'WETH',
      }
    ],
  },
  {
    chainId: 5,
    name: 'Goerli',
    slug: 'goerli',
    explorerAddressUrl: 'https://goerli.etherscan.io/address/{address}',
    explorerTransactionUrl: 'https://goerli.etherscan.io/tx/{hash}',
    testnet: true,
  },
  {
    chainId: 42,
    name: 'Kovan',
    slug: 'kovan',
    explorerAddressUrl: 'https://kovan.etherscan.io/address/{address}',
    explorerTransactionUrl: 'https://kovan.etherscan.io/tx/{hash}',
    testnet: true,
  },
  {
    chainId: 137,
    name: 'Polygon',
    slug: 'polygon',
    explorerAddressUrl: 'https://polygonscan.com/address/{address}',
    explorerTransactionUrl: 'https://polygonscan.com/tx/{hash}',
    testnet: false,
  },
  {
    chainId: 80001,
    name: 'Mumbai',
    slug: 'mumbai',
    explorerAddressUrl: 'https://mumbai.polygonscan.com/address/{address}',
    explorerTransactionUrl: 'https://mumbai.polygonscan.com/tx/{hash}',
    testnet: true,
  },
  {
    chainId: 56,
    name: 'Binance Smart Chain',
    slug: 'binance',
    explorerAddressUrl: 'https://www.bscscan.com/address/{address}',
    explorerTransactionUrl: 'https://www.bscscan.com/tx/{hash}',
    testnet: false,
  },
  {
    chainId: 97,
    name: 'BSC Testnet',
    slug: 'bsc-testnet',
    explorerAddressUrl: 'https://testnet.bscscan.com/address/{address}',
    explorerTransactionUrl: 'https://testnet.bscscan.com/tx/{hash}',
    testnet: false,
  },
];

if (process.env.NODE_ENV !== 'production') {
  chains.push({
    chainId: 1337,
    name: 'Local Dev',
    slug: 'local',
    testnet: true,
    contracts: {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      market: require('../../addresses.json').nftMarketAddress,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      nft: require('../../addresses.json').nftAddress,
    },
    currencies: [
      {
        address: ethers.constants.AddressZero,
        name: 'Ether',
        symbol: 'ETH',
      },
      {
        address: '0x73511669fd4dE447feD18BB79bAFeAC93aB7F31f',
        name: 'Wrapped Ethereum',
        symbol: 'WETH',
      }
    ]
  });
}

function mapChainInfo(id: number, info?: BlockchainInfo): BlockchainInfo {
  const network = ethers.providers.getNetwork(id);

  return {
    ...(info || {}),
    chainId: id,
    name: info?.name ?? network?.name ?? BigNumber.from(id ?? 0).toHexString(),
    ensAddress: info?.ensAddress ?? network?.ensAddress,
    testnet: info?.testnet ?? false,
  };
}

export function isChainSupported(info: BlockchainInfo): boolean {
  // Se não houver contratos definidos
  if (!info.contracts)
    return false;

  const isTestnet = info.testnet ?? false;

  // Em ambiente de testes, apenas testnets são suportadas
  // Em ambiente de produção, testnets não são suportadas
  if (isTestnet != environment.blockchain.useTestnets)
    return false;

  return true;
}

export function getSupportedChains(): BlockchainInfo[] {
  return chains
    .filter(c => isChainSupported(c))
    .map(c => mapChainInfo(c.chainId, c));
}

export function findChain(id: number): BlockchainInfo {
  return mapChainInfo(id, chains.find(c => c.chainId === id));
}

export function findChainBySlug(slug: string): BlockchainInfo | undefined {
  const chain = chains.find(c => c.slug === slug);

  if (!chain)
    return undefined;

  return mapChainInfo(chain.chainId, chain);
}
