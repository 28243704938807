import styled from '@emotion/styled';
import { ContentContainer } from '../ContentContainer';


export const Container = styled.footer`
  background: #2C2C31;
  color: #BCBCBC;
  padding: 1rem;
  font-size: 0.8rem;
  line-height: 1rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
  gap: 2rem;
`;

export const Logo = styled.img`
  margin-bottom: 1rem;
  max-width: 100%;
  max-height: 4rem;
`;

export const Title = styled.h6`
  color: #F7941D;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  margin: 1rem 0;
`;

export const Links = styled.ul`
  list-style: none;
  margin: 0;
  
  li {
    margin: 1em 0;
  }
  
  a {
    color: #BCBCBC;
  }
  a:hover {
    color: #fff;
  }
`;

export const HorizontalRule = styled.hr`
  color: #707070;
  border: 0;
  border-top: 1px solid #707070;
  margin: 2rem 0;
`;

export const Copyright = styled.div`
  display: flex;
  justify-content: space-between;
  
  ${Paragraph} {
    margin: 0;
  }

  ${Links} {
    display: flex;

    li {
      margin: 0 0 0 2rem;
    }
  }
`;
