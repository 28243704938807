import { ReactElement, useMemo } from 'react';
import { TokenAttributesBox } from './attributes';
import { TokenDetailsBox } from './details';
import { NftProxy } from '../../models/proxies/nft.proxy';
import { ImageContainer, ImageObject, ImageWrapper } from './styles';
import { NftMetadataStruct } from '../../models/structs/nft-metadata.struct';

export interface NFTInfoProps {
  nft: NftProxy;
  metadata: NftMetadataStruct;
}

export function NFTInfo({ nft, metadata }: NFTInfoProps): ReactElement {
  return (
    <div>
      <ImageContainer>
        <ImageWrapper background={metadata.background_color}>
          <ImageObject metadata={metadata} rich={true} />
        </ImageWrapper>
      </ImageContainer>

      <TokenAttributesBox metadata={nft.metadata} />

      <TokenDetailsBox nft={nft} />
    </div>
  );
}
