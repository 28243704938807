import { ReactElement, useMemo } from 'react';
import useWeb3 from '../../store/useWeb3';
import { findChain, getSupportedChains, isChainSupported } from '../../services/chains';
import { InvalidBlockchain } from './styles';

export function CurrentChain(): ReactElement {
  const chainId = useWeb3(s => s.chainId);

  const chain = useMemo(() => findChain(chainId), [chainId]);

  const supportedChains = useMemo(() => getSupportedChains(), []);
  const isSupported = useMemo(() => isChainSupported(chain), [chain]);

  if (!chainId || isSupported)
    return <div />;

  return (
    <InvalidBlockchain>
      Detectamos que você está conectado a rede {chain.name}, que não é suportada.
      Por favor, conecte em {supportedChains.map(c => c.name).join(', ')}.
    </InvalidBlockchain>
  );
}
