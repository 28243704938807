import { BigNumber, ethers } from 'ethers';

export function parseUnits(amount: string, what?: string): BigNumber {
  try {
    return ethers.utils.parseUnits(amount, 18);
  } catch (err) {
    const code: string = err.code;
    const fault: string = err.fault;

    if (code === 'NUMERIC_FAULT' && fault === 'underflow')
      throw new Error('O valor ' + (what || 'digitado') + ' é muito baixo');

    throw err;
  }
}
