import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
`;

export const AboutCard = styled.div`
  background: radial-gradient(closest-side at 50% 0%, #FFFAEE 0%, #FFFFFF 100%);
  box-shadow: 0px 3px 6px #00000029;
  padding: 2rem;
  border-radius: 1rem;
`;

export const AboutIcon = styled.div`
  background: #F7941D;
  border-radius: 0.5rem;
  width: 4rem;
  height: 4rem;
  margin-bottom: 3rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AboutIconImage = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
`;

export const AboutCardTitle = styled.h6`
  font-size: 1.3rem;
  font-weight: 600;
  color: #454550;
  margin: 2rem 0;
`;

export const AboutCardParagraph = styled.p`
  color: #979797;
  font-size: 0.9rem;
  line-height: 1.25rem;
`;
