import { ReactElement, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { PageTitle } from '../../components/PageTitle';
import { NFTCard } from '../../components/NFTCard';
import { NFTGrid } from '../../components/NFTGrid';
import { createErrorNotification } from '../../services/notification';
import { ContentContainer } from '../../components/ContentContainer';
import { getMarketHighlights } from '../../services/market';
import { NftProxy } from '../../models/proxies/nft.proxy';
import { EmptyMessage } from '../../components/EmptyMessage';

export function DiscoverPage(): ReactElement {
  const [nfts, setNfts] = useState<NftProxy[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    getMarketHighlights(50)
      .then(data => setNfts(data.map(item => ({
        ...item.token,
        currentMarketItem: item,
      }))))
      .catch(error => createErrorNotification(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ContentContainer>
      <PageTitle>Descubra</PageTitle>
      <Spin spinning={isLoading}>
        {!isLoading && nfts?.length === 0 && (
          <EmptyMessage>Nenhuma NFT encontrada!</EmptyMessage>
        )}
        <NFTGrid>
          {nfts.map((nft, i) =>
            <NFTCard nft={nft} key={i} />
          )}
        </NFTGrid>
      </Spin>
    </ContentContainer>
  );
}
