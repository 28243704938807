import styled from '@emotion/styled';

export const DisplayImage = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
  user-select: none;
`;

export const DisplayVideo = styled.video`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export const DisplayAudioContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
`;

export const DisplayAudio = styled.audio`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2rem);
  margin: 1rem;
`;

export const DisplayIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  
  border: none;
  overflow: hidden;
`;
