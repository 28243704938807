import { BigNumber, BigNumberish, Contract, ethers, Signer } from 'ethers';
import { findChain, isChainSupported } from './chains';

import * as erc20 from '../abis/erc20.json';
import useWeb3 from '../store/useWeb3';

function getCurrencyContract(signer: Signer, chainId: number, currency: string): Contract {
  const chain = findChain(chainId);

  if (!isChainSupported(chain) || !chain.contracts?.market)
    throw new Error(`A rede conectada (${chain.name}) não é suportada`);

  return new Contract(currency, erc20.output.abi, signer);
}

async function getCurrencyAllowance(contract: Contract, address: string, marketChainId: number): Promise<BigNumber> {
  const chain = findChain(marketChainId);

  const remaining = await contract.callStatic.allowance(address, chain.contracts.market);

  return BigNumber.from(remaining);
}

async function approveCurrencyAllowance(contract: Contract, marketChainId: number, allowance: BigNumberish): Promise<void> {
  const chain = findChain(marketChainId);

  const transaction: ethers.ContractTransaction = await contract.functions.approve(chain.contracts.market, allowance);

  await transaction.wait();
}

export async function increaseCurrencyAllowance(marketChainId: number, currency: string, allowance: BigNumberish): Promise<void> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível realizar essa ação sem conectar a carteira');

  if (marketChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(marketChainId).name);

  const signer = provider.getSigner(address);
  const contract = getCurrencyContract(signer, marketChainId, currency);

  const current = await getCurrencyAllowance(contract, address, marketChainId);

  await approveCurrencyAllowance(contract, marketChainId, current.add(allowance));
}

export async function ensureCurrencyAllowance(marketChainId: number, currency: string): Promise<void> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível realizar essa ação sem conectar a carteira');

  if (marketChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(marketChainId).name);

  const allowance = BigNumber.from(2).pow(256).sub(1);

  const signer = provider.getSigner(address);
  const contract = getCurrencyContract(signer, marketChainId, currency);

  const current = await getCurrencyAllowance(contract, address, marketChainId);

  if (current.gte(allowance))
    return;

  await approveCurrencyAllowance(contract, marketChainId, allowance);
}
