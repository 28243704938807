import { ReactElement } from 'react';
import {
  FloatingCard1, FloatingCard2,
  FloatingNFTContainer,
  IntroColumns,
  IntroContainer, LandingTextButton,
  LandingTextContainer,
  LandingTextContent, LandingTextHeading, LandingTextHeadingHighlight, LandingTextParagraph,
  LandingTextTitle
} from './styles';
import { NFTCard } from '../NFTCard';
import { NftProxy } from '../../models/proxies/nft.proxy';
import { useHistory } from 'react-router-dom';

export interface IntroHeadingProps {
  nfts: NftProxy[];
}

export function IntroHeading(props: IntroHeadingProps): ReactElement {
  const history = useHistory();

  return (
    <IntroContainer>
      <IntroColumns>

        <LandingTextContainer>
          <LandingTextContent>
            <LandingTextTitle>Smyowl Market</LandingTextTitle>
            <LandingTextHeading>
              Uma Nova Plataforma<br />
              Para Criar e Vender<br />
              <LandingTextHeadingHighlight>NFTs.</LandingTextHeadingHighlight>
            </LandingTextHeading>
            <LandingTextParagraph>
              Compre, venda e descubra itens digitais exclusivos organizados em nossas coleções criada por nossos artistas! Venha fazer parte do mercado digital de colecionáveis cripto e tokens não fungíveis (NFTs).
            </LandingTextParagraph>
            <LandingTextButton onClick={() => history.push('/collections')}>Explorar</LandingTextButton>
          </LandingTextContent>
        </LandingTextContainer>

        <div>
          <FloatingNFTContainer>
            {props.nfts.length > 1 && <FloatingCard1><NFTCard nft={props.nfts[1]} /></FloatingCard1>}
            {props.nfts.length > 0 && <FloatingCard2><NFTCard nft={props.nfts[0]} /></FloatingCard2>}
          </FloatingNFTContainer>
        </div>

      </IntroColumns>
    </IntroContainer>
  );
}
