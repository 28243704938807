import React, { ReactElement, useState } from 'react';
import { ButtonContainer, ColumnHeader, ColumnHeaderTitle, Description, Line, ModalContainer, Title } from './styles';
import { PropertyInput } from './inputs';
import { NftMetadataAttributeStruct } from '../../models/structs/nft-metadata-attribute.struct';
import { Button, SecondaryButton } from '../Button';
import { createErrorNotification } from '../../services/notification';

export interface EditPropertyModalProps {
  type: 'text' | 'number' | 'date';
  attributes: NftMetadataAttributeStruct[];
  onSave: (attributes: NftMetadataAttributeStruct[]) => void;
}

export function EditPropertyModal(props: EditPropertyModalProps): ReactElement {
  const [attributes, setAttributes] = useState<NftMetadataAttributeStruct[]>(props.attributes);

  function onAdd() {
    setAttributes(attributes => [...attributes, { trait_type: '', value: '' }]);
  }

  function onChange(index: number, attribute: NftMetadataAttributeStruct) {
    setAttributes(attributes => {
      const array = [...attributes];
      array[index] = attribute;

      return array;
    });
  }

  function onRemove(index: number) {
    setAttributes(attributes => {
      const array = [...attributes];
      array.splice(index, 1);

      return array;
    });
  }

  function onSave(e: React.MouseEvent) {
    e.preventDefault();

    if (attributes.find(a => !a.trait_type || !a.value))
      return createErrorNotification('Todas as propriedades devem estar preenchidas');

    props.onSave(attributes.filter(a => a.trait_type));
  }

  return (
    <ModalContainer>
      <Title>Adicionar Propriedades</Title>
      <Description>As propriedades aparecem abaixo do seu item.</Description>

      <div>
        {attributes.length > 0 &&
          <Line>
            <ColumnHeader/>
            <ColumnHeaderTitle>Nome</ColumnHeaderTitle>
            <ColumnHeader>Valor</ColumnHeader>
          </Line>
        }

        {attributes.map((attribute, i) =>
          <PropertyInput key={i} type={props.type} value={attribute}
                         onChange={data => onChange(i, data)}
                         onRemove={() => onRemove(i)} />
        )}
      </div>

      <SecondaryButton onClick={onAdd}>Adicionar</SecondaryButton>

      <ButtonContainer>
        <Button onClick={onSave}>Salvar</Button>
      </ButtonContainer>
    </ModalContainer>
  );
}
