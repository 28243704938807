import { ReactElement } from 'react';
import { Rows } from '../../../../components/Rows';
import { NftProxy } from '../../../../models/proxies/nft.proxy';
import { Button } from '../../../../components/Button';
import { Details, DetailsContent } from '../../../../components/Details';
import { BuyButton, Price } from './styles';
import { NftMarketItemBidProxy } from '../../../../models/proxies/nft-market-item-bid.proxy';
import useWeb3 from '../../../../store/useWeb3';
import { Countdown } from '../../../../components/Countdown';
import { getMethodName } from '../../../../utils/market';

export interface MarketBoxProps {
  nft: NftProxy;
  bids?: NftMarketItemBidProxy[];
  onSell: () => void;
  onUpdate: () => void;
}

export function MarketBox(props: MarketBoxProps): ReactElement {
  const nft = props.nft;
  const market = nft.currentMarketItem;

  const address = useWeb3(s => s.address);
  const isOwner = nft.ownerAddress === address;

  if ((!market || !market.isActive) && isOwner) {
    return (
      <Details>
        <DetailsContent>
          <Rows>
            <div>
              <p>Essa NFT não está no mercado.</p>
            </div>
            <div>
              <Button onClick={props.onSell}>Vender</Button>
            </div>
          </Rows>
        </DetailsContent>
      </Details>
    );
  }

  if (!market || (!market.isActive && !isOwner))
    return <div />;

  return (
    <>
      <Details title={getMethodName(market.method)}>
        <DetailsContent>
          <Countdown timestamp={market.expiresAt} /> restantes!
        </DetailsContent>
      </Details>
      <Details>
        <DetailsContent>
          <Rows>
            <div>
              <strong>Preço</strong>
              <Price price={market.price} chainId={market.tokenContractChainId} currency={market.currency} />
            </div>
            <div>
              <BuyButton item={market} onUpdate={props.onUpdate} showRemoveButton />
            </div>
          </Rows>
        </DetailsContent>
      </Details>
    </>
  );
}
