import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: block;
  background: #fff;
  box-shadow: 0 1px 14px #123A774D;
  border-radius: 0.75em;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  
  &:hover {
    transform: scale(1.05);
  }
`;

export const ContainerPlaceholder = styled(Container)`
  opacity: 0.5;
  cursor: default;
`;

export const CoverContainer = styled.div<{ src: string }>`
  height: 6rem;
  background: transparent;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  margin-bottom: -2.5rem;
`;

export const ImageContainerObject = styled.img`
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  background: #fff;
  border: 0.2rem solid #fff;
`;

export const Content = styled.div`
  margin: 1rem;
`;

export const Title = styled.h4`
  color: #454550;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  margin: 1rem 0;
`;

export const Paragraph = styled.p`
  color: #777777;
  font-size: 0.75rem;
  text-align: center;
  line-height: 1rem;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
