import styled from '@emotion/styled';

export const NFTGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;

  @media(max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media(max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    font-size: 0.95rem;
  }
  
  @media(max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    font-size: 0.9rem;
  }

  @media(max-width: 300px) {
    font-size: 0.8rem;
  }
`;
