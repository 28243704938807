import { NftMarketItemBidProxy } from '../models/proxies/nft-market-item-bid.proxy';
import { GetManyDefaultResponseProxy } from '../models/proxies/base/get-many-default-response.proxy';
import { BigNumberish, ethers } from 'ethers';
import useWeb3 from '../store/useWeb3';
import { findChain } from './chains';
import { getMarketContract, refreshMarketItem } from './market';
import { NftMarketItemProxy } from '../models/proxies/nft-market-item.proxy';
import { ensureCurrencyAllowance } from './currencies';
import { api, publicApi } from './api';

export async function getBids(chainId: number, marketItemId: string): Promise<NftMarketItemBidProxy[]> {
  const query = {
    isActive: true,
  };

  const url = `/networks/${encodeURIComponent(chainId)}/market/items/${encodeURIComponent(marketItemId)}/bids?s=${JSON.stringify(query)}`;
  const { data } = await publicApi.get<GetManyDefaultResponseProxy<NftMarketItemBidProxy>>(url);

  return data.data;
}

export async function getBid(chainId: number, marketItemId: string, bidder: string): Promise<NftMarketItemBidProxy> {
  const url = `/networks/${encodeURIComponent(chainId)}/market/items/${encodeURIComponent(marketItemId)}/bids/${encodeURIComponent(bidder)}`;
  const { data } = await publicApi.get<NftMarketItemBidProxy>(url);

  return data;
}

export async function refreshBid(chainId: number, marketItemId: string, bidder: string): Promise<NftMarketItemBidProxy> {
  const url = `/networks/${encodeURIComponent(chainId)}/market/items/${encodeURIComponent(marketItemId)}/bids/${encodeURIComponent(bidder)}/refresh`;
  const { data } = await api.post<NftMarketItemBidProxy>(url);

  return data;
}

async function placeBid(market: NftMarketItemProxy, value: BigNumberish): Promise<string> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível criar um lance sem conectar a carteira');

  if (market.tokenContractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(market.tokenContractChainId).name);

  await ensureCurrencyAllowance(market.tokenContractChainId, market.currency);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const transaction: ethers.ContractTransaction = await contract.functions.placeBid(market.id, value);

  const receipt = await transaction.wait();
  const event = receipt.events.find(event => event.event === 'BidPlaced');

  return event.args.bidder.toString();
}

export async function createBid(market: NftMarketItemProxy, value: BigNumberish): Promise<Partial<NftMarketItemBidProxy>> {
  const bidder = await placeBid(market, value);

  return await refreshBid(market.tokenContractChainId, market.id, bidder)
    .catch(() => ({
      value: value.toString(),
      bidderAddress: bidder,
      marketItemId: market.id,
      marketItem: market,
      isActive: true,
    }));
}

export async function removeBid(market: NftMarketItemProxy): Promise<Partial<NftMarketItemBidProxy>> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível remover um lance sem conectar a carteira');

  if (market.tokenContractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(market.tokenContractChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const transaction: ethers.ContractTransaction = await contract.functions.withdrawBid(market.id);

  const receipt = await transaction.wait();
  const event = receipt.events.find(event => event.event === 'BidWithdrawn');

  const bidder = event.args.bidder.toString();

  return await refreshBid(chainId, market.id, bidder)
    .catch(() => ({
      bidderAddress: bidder,
      value: event.args.value.toString(),
      marketItem: market,
      marketItemId: market.id,
      marketChainId: market.tokenContractChainId,
      isActive: false,
    }));
}


export async function acceptBid(market: NftMarketItemProxy, bidder: string, value: BigNumberish): Promise<Partial<NftMarketItemBidProxy>> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível remover um lance sem conectar a carteira');

  if (market.tokenContractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(market.tokenContractChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const transaction: ethers.ContractTransaction = await contract.functions.acceptBid(market.id, bidder, value);

  const receipt = await transaction.wait();
  const event = receipt.events.find(event => event.event === 'MarketItemSold');

  await refreshMarketItem(chainId, market.id)
    .catch((err) => console.error(err));

  return await refreshBid(chainId, market.id, bidder)
    .catch(() => ({
      bidderAddress: bidder,
      value: event.args.value.toString(),
      marketItem: market,
      marketItemId: market.id,
      marketChainId: market.tokenContractChainId,
      isActive: false,
    }));
}
