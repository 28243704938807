import React, { ReactElement } from 'react';
import { Label, LabelDescription, LabelText } from '../Label';

export interface TextInputBaseProps {
  label: string;
  description?: string;
  onChange: (value: string) => void;
}

export type TextInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & TextInputBaseProps;

export type TextAreaInputProps = Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & TextInputBaseProps

export function TextInput(props: TextInputProps): ReactElement {
  const { label, description, type, onChange, ...innerProps } = props;

  return (
    <Label>
      <LabelText>{label}</LabelText>

      {description && <LabelDescription>{description}</LabelDescription>}

      <input
        {...innerProps}
        type={type || 'text'}
        onChange={e => onChange(e.target.value)}
      />
    </Label>
  );
}

export function TextAreaInput(props: TextAreaInputProps): ReactElement {
  const { label, description, onChange, ...innerProps } = props;

  return (
    <Label>
      <LabelText>{label}</LabelText>

      {description && <LabelDescription>{description}</LabelDescription>}

      <textarea
        {...innerProps}
        onChange={e => onChange(e.target.value)}
      />
    </Label>
  );
}
