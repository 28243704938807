import styled from '@emotion/styled';
import { PageTitle } from '../../../components/PageTitle';

export const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  
  @media(max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled(PageTitle)`
  font-size: 1.5rem;
  margin: 0.25rem 0;
  color: #404040;
  
  max-width: 50vw;
  word-wrap: break-word;
`;
