import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Details, DetailsContent } from '../../../../components/Details';
import { MarketBox } from './market';
import { CollectionBox } from './collection';
import { BidHistory } from './bids';
import { TransactionHistory } from './history';
import { NftMetadataStruct } from '../../../../models/structs/nft-metadata.struct';
import { NftProxy } from '../../../../models/proxies/nft.proxy';
import { CollectionName } from './styles';
import { Title } from '../styles';

export interface NftInfoPageProps {
  nft: NftProxy;
  metadata: NftMetadataStruct;
  onUpdate?: () => void;
  onSell: () => void;
}

export function NftInfoPage({ nft, metadata, onUpdate, onSell }: NftInfoPageProps): ReactElement {
  const collection = nft.collection;

  return (
    <div>
      {collection && (
        <CollectionName>
          <Link to={'/collections/' + encodeURIComponent(collection.slug)}>
            {collection.title}
          </Link>
        </CollectionName>
      )}

      <Title>{metadata.name}</Title>

      {metadata.description && (
        <Details title="Descrição">
          <DetailsContent>
            {metadata.description?.split('\n').map((p, i) =>
              <p key={i}>{p}</p>
            )}
          </DetailsContent>
        </Details>
      )}

      <MarketBox nft={nft} onUpdate={onUpdate} onSell={onSell} />

      {/*<CollectionBox nft={nft} onUpdate={onUpdate} />*/}

      <BidHistory nft={nft} onUpdate={onUpdate} />

      <TransactionHistory nft={nft} />
    </div>
  );
}
