import { uploadJsonToIpfs, uploadToIpfs } from './ipfs';
import { BigNumber, Contract, ethers, Signer } from 'ethers';
import { environment } from '../environments/environment';
import { CreateNftPayload } from '../models/payloads/create-nft.payload';
import { NftMetadataStruct } from '../models/structs/nft-metadata.struct';
import { NftProxy } from '../models/proxies/nft.proxy';
import { GetManyDefaultResponseProxy } from '../models/proxies/base/get-many-default-response.proxy';
import { findChain, isChainSupported } from './chains';
import { addTokenToCollection } from './collections';
import { createErrorNotification } from './notification';
import useWeb3 from '../store/useWeb3';
import { publicApi } from './api';

function getNftContract(signer: Signer, chainId: number): Contract {
  const chain = findChain(chainId);

  if (!isChainSupported(chain) || !chain.contracts?.nft)
    throw new Error(`A rede conectada (${chain.name}) não é suportada para geração de NFTs`);

  return new Contract(chain.contracts.nft, environment.blockchain.nftToken.abi, signer);
}

async function mintNft(contract: Contract, address: string, uri: string, royaltiesPercentage: number): Promise<{ contractAddress: string, tokenId: string }> {
  const transaction: ethers.ContractTransaction = await contract.functions.safeMint(
    address,
    uri,
    Math.round(royaltiesPercentage * 10000),
  );

  const receipt = await transaction.wait();
  const event = receipt.events.find(event => event.event === 'Transfer');

  const tokenId: BigNumber = event.args.tokenId;

  return {
    contractAddress: contract.address.toLowerCase(),
    tokenId: tokenId.toString(),
  };
}

export async function isNftApproved(signer: Signer, nftAddress: string, tokenId: string, owner: string, operator: string, isERC721: boolean): Promise<boolean> {
  const contract = new ethers.Contract(nftAddress, environment.blockchain.nftToken.abi, signer);

  if (isERC721) {
    const approvedAddress = await contract.callStatic.getApproved(tokenId);

    if (approvedAddress === owner)
      return true;
  }

  return await contract.callStatic.isApprovedForAll(owner, operator);
}

export async function setNftApprovalForAll(signer: Signer, nftAddress: string, operator: string, approved: boolean): Promise<void> {
  const contract = new ethers.Contract(nftAddress, environment.blockchain.nftToken.abi, signer);

  const transaction: ethers.ContractTransaction = await contract.functions.setApprovalForAll(
    operator,
    approved,
  );

  const receipt = await transaction.wait();
  const event = receipt.events.find(event => event.event === 'ApprovalForAll');

  if (event.args.approved !== approved)
    throw new Error('Falha ao aprovar o marketplace. Estado inválido.');
}

function validateNftMetadata(payload: CreateNftPayload): void {
  if (!payload.image)
    throw new Error('A NFT deve ter uma imagem');

  if (!payload.name?.trim())
    throw new Error('O nome da NFT deve ser definido');

  if (!payload.collection)
    throw new Error('A coleção da NFT deve ser definida');
}

function createNftMetadata(payload: CreateNftPayload, imageUrl: string): NftMetadataStruct {
  return {
    name: payload.name?.trim(),
    description: payload.description?.trim(),
    attributes: payload.attributes,
    image: imageUrl,
  };
}

export async function createNft(payload: CreateNftPayload): Promise<Partial<NftProxy>> {
  validateNftMetadata(payload);

  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível criar uma NFT sem conectar a carteira');

  if (!payload.collection)
    throw new Error('A coleção da NFT deve ser definida');

  const signer = provider.getSigner(address);
  const contract = getNftContract(signer, chainId);

  const imageIpfs = await uploadToIpfs(payload.image);

  const metadata = createNftMetadata(payload, imageIpfs.ipfsUri);
  const metadataIpfs = await uploadJsonToIpfs(metadata);

  const token = await mintNft(contract, address, metadataIpfs.ipfsUri, payload.royaltiesPercentage);

  await addTokenToCollection({
    collectionId: payload.collection,
    tokenContractAddress: token.contractAddress,
    tokenContractChainId: chainId,
    tokenId: token.tokenId,
  }).catch(err => createErrorNotification(err));

  return {
    contractChainId: chainId,
    contractAddress: token.contractAddress,
    id: token.tokenId,
    tokenUri: metadataIpfs.ipfsUri,
    ownerAddress: address,
    metadata,
  };
}

export async function getNft(chainId: number, tokenAddress: string, tokenId: string): Promise<NftProxy> {
  const url = `/networks/${encodeURIComponent(chainId)}/contracts/${encodeURIComponent(tokenAddress)}/nfts/${encodeURIComponent(tokenId)}`;

  const { data } = await publicApi.get<NftProxy>(url);

  return data;
}

export async function getNftsByOwner(userAddress: string, page: number, limit: number = 50): Promise<GetManyDefaultResponseProxy<NftProxy>> {
  const search = { ownerAddress: userAddress };

  const url = `/nfts?s=${encodeURIComponent(JSON.stringify(search))}&limit=${limit}&page=${page}`;

  const { data } = await publicApi.get<GetManyDefaultResponseProxy<NftProxy>>(url);

  return data;
}
