import React, { ReactElement, useEffect, useState } from 'react';
import { Spin } from 'antd';

import { IntroHeading } from '../../components/IntroHeading';
import { CollectionCard } from '../../components/CollectionCard';
import { ContentContainer } from '../../components/ContentContainer';
import { NFTGrid } from '../../components/NFTGrid';
import { SectionTitle } from '../../components/SectionTitle';
import { NFTCard } from '../../components/NFTCard';
import { NftProxy } from '../../models/proxies/nft.proxy';
import { CollectionProxy } from '../../models/proxies/collection.proxy';
import { getMarketHighlights } from '../../services/market';
import { createErrorNotification } from '../../services/notification';
import { getCollections } from '../../services/collections';
import { HowItWorksSection } from './sections';

const placeholderTokens: NftProxy[] = new Array<NftProxy>(6).fill({
  id: '',
  contractAddress: '',
  contractChainId: 0,
  isMetadataCentralized: false,
  isPlaceholder: true,
});

const placeholderCollections: CollectionProxy[] = new Array<CollectionProxy>(4).fill({
  id: 0,
  slug: '',
  description: '',
  title: '',
  pictureUrl: '',
  creatorAddress: '',
  isPlaceholder: true,
});

export function LandingPage(): ReactElement {
  const [collections, setCollections] = useState<CollectionProxy[]>(placeholderCollections);
  const [nfts, setNfts] = useState<NftProxy[]>(placeholderTokens);
  const [isCollectionsLoading, setCollectionsLoading] = useState<boolean>(false);
  const [isNftsLoading, setNftsLoading] = useState<boolean>(false);

  useEffect(() => {
    setNftsLoading(true);
    setCollectionsLoading(true);

    getMarketHighlights(6)
      .then(data => setNfts(data.map(item => ({
        ...item.token,
        currentMarketItem: item,
      }))))
      .catch(error => createErrorNotification(error))
      .finally(() => setNftsLoading(false));

    getCollections({ limit: 4 })
      .then(data => setCollections(data.data))
      .catch(error => createErrorNotification(error))
      .finally(() => setCollectionsLoading(false))

  }, []);

  return (
    <div>
      <IntroHeading nfts={nfts.slice(0, 2)} />

      {collections.length > 0 && (
        <ContentContainer>
          <Spin spinning={isCollectionsLoading}>
            <SectionTitle>Coleções em destaque</SectionTitle>
            <NFTGrid>
              {collections.slice(0, 4).map((collection, i) =>
                <CollectionCard collection={collection} key={i} />
              )}
            </NFTGrid>
          </Spin>
        </ContentContainer>
      )}

      {nfts.length > 2 && (
        <ContentContainer>
          <Spin spinning={isNftsLoading}>
            <SectionTitle>Artes em destaque</SectionTitle>
            <NFTGrid>
              {nfts.slice(2, 6).map((nft, i) =>
                <NFTCard nft={nft} key={i} />
              )}
            </NFTGrid>
          </Spin>
        </ContentContainer>
      )}

      <HowItWorksSection />
    </div>
  );
}
