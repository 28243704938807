import { ReactElement, useState } from 'react';
import { NFTDisplayImage, NFTDisplayImageProps } from './image';
import { DisplayVideo } from './styles';

export interface NFTDisplayVideoProps extends NFTDisplayImageProps {
  fallbackImage: string;
}

export function NFTDisplayVideo(props: NFTDisplayVideoProps): ReactElement {
  const [shouldFallback, setShouldFallback] = useState<boolean>(false);

  if (shouldFallback)
    return <NFTDisplayImage {...props} src={props.fallbackImage} />;

  return (
    <DisplayVideo
      src={props.src}
      aria-label={props.alt}
      className={props.className}
      muted
      autoPlay
      loop
      controls={!props.onClick}
      onClick={props.onClick}
      onError={() => setShouldFallback(true)}
    />
  );
}
