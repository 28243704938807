import { ReactElement, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { PageTitle } from '../../../components/PageTitle';
import { NFTGrid } from '../../../components/NFTGrid';
import { createErrorNotification } from '../../../services/notification';
import { ContentContainer } from '../../../components/ContentContainer';
import { CollectionCard } from '../../../components/CollectionCard';
import { getCollections } from '../../../services/collections';
import { CollectionProxy } from '../../../models/proxies/collection.proxy';
import { GetManyDefaultResponseProxy } from '../../../models/proxies/base/get-many-default-response.proxy';
import useWeb3 from '../../../store/useWeb3';
import { EmptyMessage } from '../../../components/EmptyMessage';

export function CollectionMinePage(): ReactElement {
  const address = useWeb3(s => s.address);
  const [page, setPage] = useState<number>(0);
  const [collections, setCollections] = useState<GetManyDefaultResponseProxy<CollectionProxy>>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    getCollections({ page, search: { creatorAddress: address } })
      .then(data => setCollections(data))
      .catch(err => createErrorNotification(err.message))
      .finally(() => setLoading(false));
  }, [address, page]);

  return (
    <ContentContainer>
      <PageTitle center>Minhas Coleções</PageTitle>
      <Spin spinning={isLoading}>
        {!isLoading && collections?.total === 0 && (
          <EmptyMessage>Você ainda não tem nenhuma coleção!</EmptyMessage>
        )}
        <NFTGrid>
          {collections?.data.map((collection, i) =>
            <CollectionCard collection={collection} key={i} />
          )}
        </NFTGrid>
      </Spin>
    </ContentContainer>
  );
}
