import React, { ReactElement, ReactNode } from 'react';
import { Method, MethodSelector } from './styles';
import { SaleMethodEnum } from '../../../../models/enums/sale-method.enum';
import { LabelDiv, LabelText } from '../../../../components/Label';
import { getMethodDescription } from './utils';

export interface MethodSelectorInputProps {
  label: string;
  value: SaleMethodEnum;
  onChange: (method: SaleMethodEnum) => void;
}

export function MethodSelectorInput({ label, onChange, value }: MethodSelectorInputProps): ReactElement {
  return (
    <LabelDiv>
      <LabelText>{label}</LabelText>

      <MethodSelector>
        <MethodItem value={SaleMethodEnum.DUTCH_AUCTION} onChange={onChange} current={value}>
          Vender com um preço em queda
        </MethodItem>
        <MethodItem value={SaleMethodEnum.ENGLISH_AUCTION} onChange={onChange} current={value}>
          Vender para o lance maior
        </MethodItem>
        <MethodItem value={SaleMethodEnum.FIXED_PRICE} onChange={onChange} current={value}>
          Preço fixo
        </MethodItem>
      </MethodSelector>

      <p>{getMethodDescription(value)}</p>

    </LabelDiv>
  );
}

interface MethodItemProps {
  current?: SaleMethodEnum;
  value: SaleMethodEnum;
  onChange: (method: SaleMethodEnum) => void;
  children: ReactNode;
}

function MethodItem(props: MethodItemProps): ReactElement {
  function onChange(e: React.MouseEvent) {
    e.preventDefault();

    props.onChange(props.value);
  }

  return (
    <Method onClick={onChange} className={props.value === props.current ? 'active' : ''}>{props.children}</Method>
  );
}
