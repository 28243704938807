import { ReactElement, useEffect, useState } from 'react';
import { ContentContainer } from '../../../components/ContentContainer';
import { CoverContainer, Description, EditButton, ImageObject, SubTitle, Title } from './styles';
import { NFTGrid } from '../../../components/NFTGrid';
import { NFTCard } from '../../../components/NFTCard';
import { CollectionProxy } from '../../../models/proxies/collection.proxy';
import { getCollection } from '../../../services/collections';
import { useHistory, useParams } from 'react-router-dom';
import { createErrorNotification } from '../../../services/notification';
import { Spin } from 'antd';
import { SocialIcons } from '../../../components/SocialIcons';

import backgroundImage from '../../../assets/images/background.png';
import { Button } from '../../../components/Button';
import useAuth from '../../../store/useAuth';

const placeholderCollection: CollectionProxy = {
  id: 0,
  slug: '',
  title: '',
  description: '',
  pictureUrl: '',
  creatorAddress: '',
  items: [],
};

export function CollectionViewPage(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const [collection, setCollection] = useState<CollectionProxy>(placeholderCollection);
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);

    getCollection(id)
      .then(collection => setCollection(collection))
      .catch(err => createErrorNotification(err.message))
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <Spin spinning={loading}>
      <CollectionView collection={collection} />
    </Spin>
  );
}

function CollectionView({ collection }: { collection: CollectionProxy }): ReactElement {
  const history = useHistory();
  const address = useAuth(s => s.address);

  return (
    <div>
      <CoverContainer src={collection.coverUrl} />
      <ImageObject src={collection.pictureUrl || backgroundImage} />
      <Title>{collection.title}</Title>
      <Description>
        <p>{collection.description}</p>

        {(collection.siteUrl || collection.discordUrl || collection.twitterUrl || collection.instagramUrl || collection.email) && (
          <>
            <SubTitle>Redes sociais</SubTitle>
            <SocialIcons
              siteUrl={collection.siteUrl}
              discordUrl={collection.discordUrl}
              twitterUrl={collection.twitterUrl}
              instagramUrl={collection.instagramUrl}
              email={collection.email}
            />
          </>
        )}

        {collection.creatorAddress === address && (
          <div>
            <SubTitle>Ações</SubTitle>
            <EditButton onClick={() => history.push(`/collections/${collection.slug}/edit`)}>
              Editar
            </EditButton>
          </div>
        )}
      </Description>
      <ContentContainer>
        <NFTGrid>
          {collection.items?.map((item, i) => (
            <NFTCard key={i} nft={item.token} />
          ))}
        </NFTGrid>
      </ContentContainer>
    </div>
  );
}
