import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Link = styled.a`
  display: block;
  padding: 0.5rem;
  margin: 0 0.25rem;
  
  img {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
`;
