import React, { ReactElement } from 'react';
import { SectionTitle } from '../../components/SectionTitle';
import { AboutCard, AboutCardParagraph, AboutCardTitle, AboutGrid, AboutIcon, AboutIconImage } from './styles';
import { ContentContainer } from '../../components/ContentContainer';

import moneyIcon from '../../assets/images/icon-landing-money.svg';
import uploadIcon from '../../assets/images/icon-landing-upload.svg';
import walletIcon from '../../assets/images/icon-landing-wallet.svg';

export function HowItWorksSection(): ReactElement {
  return (
    <ContentContainer>
      <SectionTitle>Como funciona</SectionTitle>

      <AboutGrid>
        <AboutCard>
          <AboutIcon>
            <AboutIconImage src={moneyIcon} alt="Ícone" />
          </AboutIcon>
          <AboutCardTitle>Conecte sua carteira</AboutCardTitle>
          <AboutCardParagraph>
            Conecte sua carteira em nossa plataforma para adquirir os NFTs das mais variadas coleções feitas por nossa equipe! É fácil, simples e rápido adquirir nossos NFTs!
          </AboutCardParagraph>
        </AboutCard>
        <AboutCard>
          <AboutIcon>
            <AboutIconImage src={uploadIcon} alt="Ícone" />
          </AboutIcon>
          <AboutCardTitle>Explore</AboutCardTitle>
          <AboutCardParagraph>
            Explore nossa artes organizadas em diversas coleções, cada uma com sua história e características que podem aumentar ainda mais a sua raridade.
          </AboutCardParagraph>
        </AboutCard>
        <AboutCard>
          <AboutIcon>
            <AboutIconImage src={walletIcon} alt="Ícone" />
          </AboutIcon>
          <AboutCardTitle>Pague com crypto</AboutCardTitle>
          <AboutCardParagraph>
            Faça pagamento utilizando cryptomoedas de forma segura utilizando a sua própria carteira. Todas taxas são transparentes e detalhadas para você.
          </AboutCardParagraph>
        </AboutCard>
      </AboutGrid>

    </ContentContainer>
  );
}
