import { ReactElement } from 'react';
import { Container, Message } from './styles';

export interface EmptyMessageProps {
  children: string;
}

export function EmptyMessage(props: EmptyMessageProps): ReactElement {
  return (
    <Container>
      <Message>{props.children}</Message>
    </Container>
  )
}
