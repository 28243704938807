import { ReactElement } from 'react';
import { NftMetadataStruct } from '../../models/structs/nft-metadata.struct';
import { NFTDisplayImage } from './image';
import { NFTDisplayVideo } from './video';
import { NFTDisplayAudio } from './audio';
import { NFTDisplayHtml } from './html';

export interface NFTDisplayProps {
  metadata: NftMetadataStruct;
  rich?: boolean;
  className?: string;
  onClick?: () => void;
}

export function NFTDisplay({ metadata, rich, className, onClick }: NFTDisplayProps): ReactElement {
  const type = metadata.animation_type || '';
  const url = metadata.animation_url;
  const image = metadata.image;
  const alt = metadata.name;

  if (url && type.startsWith('image/'))
    return <NFTDisplayImage src={url} alt={alt} className={className} onClick={onClick} />;

  if (rich && url && type.startsWith('video/'))
    return <NFTDisplayVideo src={url} alt={alt} className={className} fallbackImage={image} onClick={onClick} />;

  if (rich && url && type.startsWith('audio/'))
    return <NFTDisplayAudio src={url} alt={alt} className={className} fallbackImage={image} onClick={onClick} />;

  if (rich && url && type === 'text/html')
    return <NFTDisplayHtml src={url} alt={alt} className={className} fallbackImage={image} onClick={onClick} />;

  // TODO 3D (GLTF, GLB)

  return <NFTDisplayImage src={image} alt={alt} className={className} onClick={onClick} />;
}
