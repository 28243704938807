import styled from '@emotion/styled';

export const TextContainer = styled.div`
  margin-top: 1em;
`;

export const TextLabel = styled.div`
  font-size: 0.8em;
  font-style: normal;
  color: #979797;
  user-select: none;
`;

export const TextContent = styled.div`
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.25em;
  max-height: 4em;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
