import { nftAddress, nftMarketAddress } from '../../addresses.json';
import NFTContract from '../../artifacts/contracts/tokens/LigaNFT.sol/LigaNFT.json';
import SmyowlMarketplace from '../../artifacts/contracts/marketplace/Marketplace.sol/SmyowlMarketplace.json';

export const environment = {
  defaultUnprotectedRoute: '/',
  defaultProtectedRoute: '/main',
  enablePWA: false,
  zustandStoreVersion: 1,
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    auth: {
      local: '/auth/local',
    },
    user: {
      me: '/users/me',
    },
  },
  blockchain: {
    useTestnets: process.env.REACT_APP_USE_TESTNETS === 'true',
    nftToken: {
      abi: NFTContract.abi,
    },
    marketplace: {
      abi: SmyowlMarketplace.abi,
    }
  },
  auth: {
    domain: process.env.REACT_APP_DOMAIN,
    expiryMinutes: 60,
  },
  limits: {
    maxNftTitle: 150,
    maxNftDescription: 2048,
    maxPropertyTitle: 50,
    maxPropertyValue: 150,
  }
};
