import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { UseWeb3Store } from './models';
import { changeAddress, connect, initialize } from './services';

export function createUseWeb3Store(): UseStore<UseWeb3Store> {
  return create(
    devtools(
      (set, get) => ({
        isInitialized: false,
        addresses: [],
        initialize: initialize(set, get),
        connect: connect(set, get),
        changeAddress: changeAddress(set, get),
      })
    ),
  );
}
