import { ReactElement, useMemo } from 'react';
import { Container, Icon } from './styles';
import { BigNumberish } from 'ethers';
import { formatPrice } from '../../utils/market';

export interface NFTPriceProps {
  price: BigNumberish;
  currency?: string;
  chainId?: number;

  className?: string;
}

export function NFTPrice(props: NFTPriceProps): ReactElement {
  const [value, icon, info] = useMemo(
    () => formatPrice(props.price, props.currency, props.chainId),
    [props.price, props.currency, props.chainId],
  );

  return (
    <Container className={props.className}>
      {icon && <Icon src={icon} alt={info?.symbol} title={info?.name} />}
      {value}
    </Container>
  );
}
