import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  min-height: 80vh;
`;

export const Message = styled.h3`
  font-size: 1.75rem;
  font-weight: 400;
  margin: auto;
  text-align: center;
  color: rgb(151, 151, 151);
`;
