import styled from '@emotion/styled';
import { NFTDisplay } from '../NFTDisplay';

export const ImageContainer = styled.div`
  width: 100%;
`;

export const ImageWrapper = styled.div<{ background?: string }>`
  width: 100%;
  padding-top: 100%;
  position: relative;
  
  background-color: ${props => props.background ? `#${props.background}` : 'transparent'};
`;

export const ImageObject = styled(NFTDisplay)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`;

export const AttributeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
`;

export const AttributeCard = styled.div`
  background: #FFF5EA;
  border: 0.05rem solid #F7941D;
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const AttributeCardTitle = styled.div`
  color: #DC6D06;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
`;

export const AttributeCardValue = styled.div`
  color: #4D4A50;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  padding-top: 0.5rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

