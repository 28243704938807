import styled from '@emotion/styled';
import { PageTitle } from '../../../../components/PageTitle';
import { NFTPrice } from '../../../../components/NFTPrice';
import { BuyNftButton } from '../../../../components/BuyNftButton';
import { SecondaryButton } from '../../../../components/Button';

export const CollectionName = styled.h2`
  margin-bottom: 1rem;
  
  a {
    color: #F7941D;
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const Price = styled(NFTPrice)`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1em;
`;

export const BuyButton = styled(BuyNftButton)`
  font-size: 1.15rem;
`;

export const BidActionButton = styled(SecondaryButton)`
  font-size: 0.75rem;
  padding: 0.75em 1.25em;
`;
