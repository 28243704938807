import { AxiosError } from 'axios';

export function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}

export function capitalize(text: string): string {
  const [firstLetter, ...otherLetters] = text?.split('') || ['', ''];

  return firstLetter?.toUpperCase() + otherLetters.join('')?.toLowerCase();
}

export function ensureHttpInUrl(url: string): string {
  if (url.startsWith('http://') || url.startsWith('https://'))
    return url;

  return 'https://invalidhttpsimageendpoint.pspsps';
}

export function extractMimetype(url: string): string {
  if (url.includes('.png'))
    return 'image/png';

  if (url.includes('.gif'))
    return 'image/gif';

  if (url.includes('.jpeg'))
    return 'image/jpeg';

  return 'image/jpg';
}

export function getErrorMessage(error: string | Error | AxiosError | any): string {
  if (typeof error === 'object')
    error = error?.response?.data?.message || error?.error?.message || error?.data?.message || error.message || error;

  return error?.toString() || 'Ocorreu um erro inesperado';
}

export function scaleToScale(value: number, oldMin: number, oldMax: number, newMin: number, newMax: number): number {
  return (((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin)) + newMin;
}

export function getCleanedAddress(address: string): string {
  return address?.trim().toLowerCase();
}

export function formatAddress(address: string): string {
  if (!address)
    return '';

  return address.substring(0, 7) + '...' + address.substring(address.length - 4);
}

export function formatTransactionHash(hash: string): string {
  if (!hash)
    return '';

  return hash.substring(0, 7) + '...' + hash.substring(hash.length - 4);
}

export function formatTokenId(id: string): string {
  if (!id)
    return '';

  if (id.length < 12)
    return id;

  return id.substring(0, 6) + '...' + id.substring(id.length - 4);
}

export function isNumber(str: string): boolean {
  const regex = /^\d+(?:\.\d+)?$/m;

  return regex.test(str) && !isNaN(parseFloat(str));
}

export function ensureObject<T>(value: T): T | undefined {
  if (typeof value === 'object' && !Array.isArray(value))
    return value;

  return undefined;
}

export function ensureArray<T>(value: T[]): T[] | undefined {
  if (Array.isArray(value))
    return value;

  return undefined;
}
