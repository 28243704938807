import { BigNumber, BigNumberish, ethers } from 'ethers';
import { GetState, SetState } from 'zustand';
import Web3Modal from 'web3modal';
import useAuth from '../useAuth';
import { Eip1193Provider } from '../../models/structs/eip-1193-provider';
import { UseWeb3Store } from './index';
import { getCleanedAddress } from '../../utils/functions';
import { getSupportedChains } from '../../services/chains';

async function initProvider(set: SetState<UseWeb3Store>, get: GetState<UseWeb3Store>): Promise<ethers.providers.Web3Provider> {
  if (get().provider)
    return get().provider;

  const supportedChains = getSupportedChains();

  const Torus = await import('@toruslabs/torus-embed').then(exports => exports.default).catch(() => undefined);

  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: {
      /*binancechainwallet: {
        package: true
      },*/
      opera: {
        package: true
      },
      torus: {
        package: Torus,
        options: {
          networkParams: {
            chainId: supportedChains[0].chainId,
          },
        },
      },
    },
  });

  const eip1193 = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(eip1193);

  set({ eip1193, provider });

  registerEvents(set, get, eip1193);

  return provider;
}

function registerEvents(set: SetState<UseWeb3Store>, get: GetState<UseWeb3Store>, eip1193: Eip1193Provider) {
  const updateChainId = (chainId: BigNumberish) => {
    const previousId = get().chainId;
    const currentId = BigNumber.from(chainId).toNumber();

    set({ chainId: currentId });
  };

  const updateAccounts = (accounts: string[]) => {
    let currentAccount = get().address;

    if (currentAccount && !accounts.includes(currentAccount)) {
      currentAccount = undefined;
      useAuth.getState().resetState();
    }

    set({ addresses: accounts, address: currentAccount });
  };

  eip1193.on('chainChanged', updateChainId);
  eip1193.on('accountsChanged', updateAccounts);

  eip1193.request({method: 'eth_chainId'})
    .then(updateChainId);
}

export function initialize(set: SetState<UseWeb3Store>, get: GetState<UseWeb3Store>): UseWeb3Store['initialize'] {
  return async function () {
    await initProvider(set, get);
  };
}

export function connect(set: SetState<UseWeb3Store>, get: GetState<UseWeb3Store>): UseWeb3Store['connect'] {
  return async function () {
    const provider = await initProvider(set, get);

    const addresses = await provider.listAccounts()
      .then(addresses => addresses.map(addr => getCleanedAddress(addr)));

    const address = addresses[0];

    console.log('connected', provider, address, addresses);

    set({ provider, addresses, address });

    return { provider, addresses, address };
  };
}

export function changeAddress(set: SetState<UseWeb3Store>, get: GetState<UseWeb3Store>): UseWeb3Store['changeAddress'] {
  return function (address: string) {
    const state = get();

    address = getCleanedAddress(address);

    if (address === state.address && !state.addresses.includes(address))
      return;

    set({ address });

    useAuth.getState().resetState();
  };
}
