import { NftLogProxy } from '../models/proxies/nft-log.proxy';
import { NftLogEventEnum } from '../models/enums/nft-log-event.enum';
import { ethers } from 'ethers';

export function getLogAction(log: NftLogProxy): [name: string, icon: string | undefined] {
  if (log.event === NftLogEventEnum.TRANSFER) {
    if (!log.fromAddress || log.fromAddress === ethers.constants.AddressZero)
      return ['Mint', undefined];

    if (!log.toAddress || log.toAddress === ethers.constants.AddressZero)
      return ['Burn', undefined];

    return ['Transferência', undefined];
  }

  if (log.event === NftLogEventEnum.LISTED)
    return ['Anunciado a venda', undefined];

  if (log.event === NftLogEventEnum.UNLISTED)
    return ['Retirado do mercado', undefined];

  if (log.event === NftLogEventEnum.SOLD)
    return ['Venda', undefined];

  if (log.event === NftLogEventEnum.BID_PLACED)
    return ['Lance', undefined];

  if (log.event === NftLogEventEnum.BID_WITHDRAWN)
    return ['Lance Removido', undefined];

  return ['', undefined];
}
