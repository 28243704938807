import styled from '@emotion/styled';
import { Button, SecondaryButton } from '../../../components/Button';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

export const UploadImageLabel = styled.div`
  width: 30rem;
  margin: 0 auto;
`;

export const UploadImageContainer = styled.div`
  border-radius: 1.5rem;
  border: 1px solid #777777;
  background: #fff;
  height: 30rem;
  width: 30rem;
  margin: 1rem auto;
  overflow: hidden;
  
  input {
    display: none;
  }
`;

export const UploadImageNothing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  img {
    max-width: 5rem !important;
  }
`;

export const UploadImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PropertyItem = styled.div`
  background: #FCFCFC;
  border: 0.05rem solid #DEDBDB;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 0;
`;

export const PropertyItemTitle = styled.div`
  margin: 0.5rem 0;
`;

export const PropertyItemValue = styled.div`
  font-weight: 700;
  margin: 0.5rem 0;
`;

export const PropertyAddButton = styled(SecondaryButton)`
  border-color: #0094FF;
  color: #0094FF;
  text-align: left;
  font-size: 1rem;
  padding: 1.25rem 1.5rem;
  margin: 0.5rem 0;
`;

export const Select = styled.select`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  
  ${Button} {
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
`;
