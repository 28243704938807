import { ReactElement, useState } from 'react';
import { NFTDisplayImage, NFTDisplayImageProps } from './image';
import { DisplayIFrame } from './styles';

export interface NFTDisplayHtmlProps extends NFTDisplayImageProps {
  fallbackImage: string;
}

export function NFTDisplayHtml(props: NFTDisplayHtmlProps): ReactElement {
  const [shouldFallback, setShouldFallback] = useState<boolean>(false);

  if (shouldFallback)
    return <NFTDisplayImage {...props} src={props.fallbackImage} />;

  return (
    <DisplayIFrame
      src={props.src}
      aria-label={props.alt}
      className={props.className}
      sandbox="allow-scripts"
      allowTransparency
      onError={() => setShouldFallback(true)}
    />
  );
}
