import { ReactElement, AnchorHTMLAttributes, useMemo } from 'react';
import { findChain } from '../../services/chains';
import { formatTransactionHash } from '../../utils/functions';

export interface TransactionLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  transactionHash: string;
  chainId: number;
}

export function TransactionLink({ transactionHash, chainId, children, ...innerProps }: TransactionLinkProps): ReactElement {
  const href = useMemo(
    () => findChain(chainId)?.explorerTransactionUrl?.replace('{hash}', transactionHash),
    [transactionHash, chainId],
  );

  const content = useMemo(
    () => children || formatTransactionHash(transactionHash),
    [children, transactionHash],
  );

  return (
    <a {...innerProps} href={href} title={transactionHash} target="_blank" rel="noreferrer noopener">
      {content}
    </a>
  );
}
