import styled from '@emotion/styled';

export const ModalContainer = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  min-width: 40rem;
  max-width: 90vw;
  padding: 1.25rem;
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
`;

export const Description = styled.p`
  color: #707070;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
`;

export const Line = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`;

export const ColumnHeader = styled.strong`
  display: block;
  min-width: 3rem;
  padding: 1rem;
`;

export const ColumnHeaderTitle = styled(ColumnHeader)`
  width: 40%;
`;

export const PropertyLine = styled(Line)`
  border: 0.05rem solid #DEDBDB;
  border-radius: 0.5rem;
  background: #FCFCFC;
  margin-bottom: 0.5rem;
`;

export const RemoveButton = styled.button`
  width: 3rem;
  height: 3rem;
  padding: 0 1rem;

  border: none;
  border-right: 0.05rem solid #DEDBDB;
  background: transparent;
  color: #6F6F6F;
  font-weight: 600;
`;

export const Input = styled.input`
  background: transparent;
  border: 0 solid #DEDBDB;
  padding: 0 1rem;
  height: 3rem;
`;

export const TitleInput = styled(Input)`
  min-width: 40%;
  border-right-width: 0.05rem;
`;

export const ValueInput = styled(Input)`
  flex: 1;
  min-width: 5rem;
`;

export const OfLabel = styled.div`
  border: 0.05rem solid #DEDBDB;
  border-top-width: 0;
  border-bottom-width: 0;
  line-height: 3rem;
  padding: 0 1rem;
  
  color: #6F6F6F;
  font-weight: 600;
  
  user-select: none;
`;

export const ButtonContainer = styled.div`
  text-align: center;
`;
