import create, { UseStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { UseAuthStore } from './models';
import { getToken, resetState, signIn } from './services';
import { getStoreName } from '../utils/functions';
import { environment } from '../../environments/environment';
import { createErrorNotification } from '../../services/notification';
import useWeb3 from '../useWeb3';

export function createAuthStore(): UseStore<UseAuthStore> {
  const name = getStoreName('useAuthStore');

  return create(
    devtools(
      persist((set, get) => ({
        isLogged: false,
        signIn: signIn(set),
        getToken: getToken(set, get),
        resetState: resetState(set),
      }), {
        name,
        version: environment.zustandStoreVersion,
        onRehydrateStorage: () => {
          return (state) => {
            if (!state.isLogged)
              return;

            useWeb3.getState().connect()
              .catch(err => createErrorNotification(err));
          };
        },
      }),
      name,
    ),
  );
}
