
export enum NftLogEventEnum {
  /**
   * Evento que simboliza a transferência de um token de uma carteira para outra.
   *
   * Quando o endereço de origem for zero, significa que foi um mint
   * Quando o endereço de destino for zero, significa que foi um burn
   */
  TRANSFER = 'Transfer',

  /**
   * Evento que simboliza o anúncio a venda
   */
  LISTED = 'MarketItemCreated',

  /**
   * Evento que simboliza a remoção do anúncio da venda
   */
  UNLISTED = 'MarketItemRemoved',

  /**
   * Evento que simboliza a venda a partir de um anúncio de venda
   */
  SOLD = 'MarketItemSold',

  /**
   * Evento que simboliza um lance feito para um anúncio de venda
   */
  BID_PLACED = 'BidPlaced',

  /**
   * Evento que simboliza um lance removido de um anúncio de venda
   */
  BID_WITHDRAWN = 'BidWithdrawn',
}
