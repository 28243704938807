import { CSSProperties, ReactElement, ReactNode } from 'react';
import { TextContainer, TextContent, TextLabel } from './styles';

export interface LabeledTextProps {
  label: string;
  children: string | ReactNode;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
}

export function LabeledText(props: LabeledTextProps): ReactElement {
  return (
    <TextContainer style={props.style} className={props.className} onClick={props.onClick}>
      <TextLabel>{props.label}</TextLabel>
      <TextContent>{props.children}</TextContent>
    </TextContainer>
  );
}
