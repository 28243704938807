import styled from '@emotion/styled';
import { css } from '@emotion/react';

const labelCss = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  input, textarea, select {
    border-radius: 0.75rem;
    border: 1px solid #777777;
    background: #fff;
    margin: 1rem 0;
    padding: 0.75rem;
  }

  textarea {
    line-height: 1.5em;
    min-height: 12.5em;
  }
`;

export const Label = styled.label`
  ${labelCss}
`;

export const LabelDiv = styled.div`
  ${labelCss}
`;

export const LabelText = styled.div`
  display: block;
  color: #393941;
  font-size: 1.25rem;
  font-weight: 600;
`;

export const LabelDescription = styled.p`
  color: #393941;
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem 0;
`;
