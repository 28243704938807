import { BigNumber, ethers } from 'ethers';
import { NftProxy } from '../../../../models/proxies/nft.proxy';
import { createMarketItem, getPriceComposition } from '../../../../services/market';
import { SaleMethodEnum } from '../../../../models/enums/sale-method.enum';
import { NftMarketItemProxy } from '../../../../models/proxies/nft-market-item.proxy';
import { parseUnits } from '../../../../utils/ethers';

/**
 * Obtém as taxas de venda de uma NFT
 *
 * @param nft A NFT
 */
export async function getSellRates(nft: NftProxy): Promise<string[]> {
  const price = 100000;

  const composition = await getPriceComposition(nft.contractChainId, nft.contractAddress, nft.id, price);

  const listing = composition.listingAmount.toNumber() / price * 100;
  const royalties = composition.royaltyAmount.toNumber() / price * 100;

  const messages: string[] = [];

  if (listing > 0)
    messages.push(`${listing.toLocaleString('pt-BR')}% do valor da venda é destinado à plataforma`);

  if (royalties > 0)
    messages.push(`${royalties.toLocaleString('pt-BR')}% do valor da venda é destinado aos royalties do NFT`);

  if (messages.length === 0)
    messages.push('Nenhuma taxa de serviço!');

  return messages;
}

/**
 * Obtém o descritivo de um método de venda
 *
 * @param method O método de venda
 */
export function getMethodDescription(method: SaleMethodEnum): string {
  if (method === SaleMethodEnum.FIXED_PRICE)
    return 'Preço fixo: onde o preço não muda.';

  if (method === SaleMethodEnum.ENGLISH_AUCTION)
    return 'Vender com preço em queda: onde o preço cai até que alguém compre.';

  if (method === SaleMethodEnum.DUTCH_AUCTION)
    return 'Vender para o maior lance: onde o maior lance vence no final.';

  return '';
}

/**
 * Valida se o formulário está preenchido
 *
 * @param method O método
 * @param endAt A data de término
 * @param price O preço de venda
 * @param minPrice O preço inicial
 * @param currency O token ERC-20 ou vazio para ether
 * @param acceptBids Se irá aceitar lances
 */
export function isFormValid(
  method: SaleMethodEnum,
  endAt: string,
  price: string,
  minPrice: string,
  currency: string,
  acceptBids: boolean,
): boolean {
  if (!endAt || !price)
    return false;

  if ((method !== SaleMethodEnum.FIXED_PRICE || acceptBids) && !currency)
    return false;

  if (method === SaleMethodEnum.DUTCH_AUCTION && !minPrice)
    return false;

  if (method === SaleMethodEnum.FIXED_PRICE && acceptBids && !minPrice)
    return false;

  return true;
}

/**
 * Cria um item no mercado a partir de um formulário
 *
 * @param nft O token
 * @param method O método
 * @param endAt A data de término
 * @param price O preço de venda
 * @param minPrice O preço inicial
 * @param currency O token ERC-20 ou vazio para ether
 * @param acceptBids Se irá aceitar lances
 */
export async function createMarketItemFromForm(
  nft: NftProxy,
  method: SaleMethodEnum,
  endAt: string,
  price: string,
  minPrice: string,
  currency: string,
  acceptBids: boolean,
): Promise<Partial<NftMarketItemProxy>> {
  if (!endAt)
    throw new Error('A data de término deve ser definida');

  const endAtDate = new Date(endAt);

  const priceNumber = parseUnits(price || '0', 'preço');
  let minPriceNumber = parseUnits(minPrice || '0', 'preço mínimo');

  if (endAtDate <= new Date())
    throw new Error('A data final deve ser no futuro');

  if (method === SaleMethodEnum.ENGLISH_AUCTION)
    minPriceNumber = BigNumber.from(0);

  if (method === SaleMethodEnum.FIXED_PRICE && !acceptBids)
    minPriceNumber = priceNumber;

  if (!currency)
    currency = ethers.constants.AddressZero;

  if (priceNumber.lt(minPriceNumber))
    throw new Error('O preço mínimo deve ser menor que o valor inicial do item');

  if (currency === ethers.constants.AddressZero && (method !== SaleMethodEnum.FIXED_PRICE || acceptBids))
    throw new Error('Ether não pode ser usado para vendas que permitam lances');

  return await createMarketItem(nft, {
    method,
    currency,
    price: priceNumber,
    minimumPrice: minPriceNumber,
    expiresAt: Math.round(+endAtDate / 1000),
  })
}
