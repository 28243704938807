import { ReactElement, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { NftProxy } from '../../../../models/proxies/nft.proxy';
import { LabelDiv, LabelText } from '../../../../components/Label';
import { getErrorMessage } from '../../../../utils/functions';
import { Rates } from './styles';
import { getSellRates } from './utils';
import useWeb3 from '../../../../store/useWeb3';

export interface SellRatesProps {
  nft: NftProxy;
}

export function SellRates(props: SellRatesProps): ReactElement {
  const address = useWeb3(s => s.address);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [rates, setRates] = useState<string[]>();

  useEffect(() => {
    if (!props.nft.contractAddress || !props.nft.id)
      return;

    setLoading(true);

    getSellRates(props.nft)
      .then(data => setRates(data))
      .catch(error => setRates([getErrorMessage(error)]))
      .finally(() => setLoading(false));
  }, [props.nft, address]);


  return (
    <LabelDiv>
      <LabelText>Taxas de serviço</LabelText>

      <Rates>
        <Spin spinning={isLoading}>
          {rates?.map((rate, i) =>
            <p key={i}>{rate}</p>
          )}
        </Spin>
      </Rates>
    </LabelDiv>
  );
}
