import { ReactElement, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { PageTitle } from '../../../components/PageTitle';
import { NFTGrid } from '../../../components/NFTGrid';
import { NFTCard } from '../../../components/NFTCard';
import { NftProxy } from '../../../models/proxies/nft.proxy';
import { createErrorNotification } from '../../../services/notification';
import { getNftsByOwner } from '../../../services/nft';
import { GetManyDefaultResponseProxy } from '../../../models/proxies/base/get-many-default-response.proxy';
import { ContentContainer } from '../../../components/ContentContainer';
import useWeb3 from '../../../store/useWeb3';
import { EmptyMessage } from '../../../components/EmptyMessage';

export function NftMinePage(): ReactElement {
  const address = useWeb3(s => s.address);
  const [nfts, setNfts] = useState<GetManyDefaultResponseProxy<NftProxy>>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!address)
      return;

    setLoading(true);

    getNftsByOwner(address, page)
      .then(items => setNfts(items))
      .catch(err => createErrorNotification(err.message))
      .finally(() => setLoading(false));
  }, [address, page]);

  return (
    <ContentContainer>
      <PageTitle>Meus NFTs</PageTitle>
      <Spin spinning={isLoading}>
        {!isLoading && nfts?.total === 0 && (
          <EmptyMessage>Você ainda não tem nenhuma NFT!</EmptyMessage>
        )}
        <NFTGrid>
          {nfts?.data.map((nft, i) =>
            <NFTCard nft={nft} key={i} />
          )}
        </NFTGrid>
      </Spin>
    </ContentContainer>
  );
}
