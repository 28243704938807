import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PageTitle } from '../../../components/PageTitle';
import { Button } from '../../../components/Button';

export const CoverContainer = styled.div<{ src?: string }>`
  min-height: ${props => props.src ? '18vw' : '6rem'};
  background: transparent;
  
  ${props => props.src && css`background-image: url(${props.src});`};
  
  background-size: cover;
  background-position: center center;
  
  margin-bottom: -5rem;
`;

export const ImageObject = styled.img`
  display: block;
  margin: 0 auto;
  width: 10rem;
  height: 10rem;
  border: 0.25rem solid #fff;
  background: #fff;
  border-radius: 50%;
  object-fit: cover;
`;

export const Title = styled(PageTitle)`
  font-size: 2rem;
  text-align: center;
  color: #454550;
  margin: 1.5rem;
`;

export const Description = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  padding: 0 1rem;
  color: #777777;
  font-size: 0.9rem;
  text-align: center;
  line-height: 1.5rem;
`;

export const SubTitle = styled.h6`
  color: #F7941D;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1.5rem;
`;

export const EditButton = styled(Button)`
  margin: 1rem;
  font-size: 1rem;
  padding: 0.5em 0.75em;
`;
