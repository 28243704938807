import { NftLogProxy } from '../models/proxies/nft-log.proxy';
import { GetManyDefaultResponseProxy } from '../models/proxies/base/get-many-default-response.proxy';
import { NftLogEventEnum } from '../models/enums/nft-log-event.enum';
import { publicApi } from './api';

export async function getNftLogs(chainId: number, contractAddress: string, tokenId: string): Promise<NftLogProxy[]> {
  const url = `/networks/${encodeURIComponent(chainId)}/contracts/${encodeURIComponent(contractAddress)}/nfts/${encodeURIComponent(tokenId)}/logs?sort=occurredAt,DESC&join[0]=from&join[1]=to`;
  const { data } = await publicApi.get<GetManyDefaultResponseProxy<NftLogProxy>>(url);

  return data.data;
}

/**
 * Essa função junta logs de transferências com logs de vendas, evitando que fiquem duplicados
 *
 * @param logs A lista de logs
 */
export function mergeRedundantNftLogs(logs: NftLogProxy[]): NftLogProxy[] {
  return logs.filter(
    log =>
      log.event !== NftLogEventEnum.TRANSFER ||
      !logs.find(l => l.event === NftLogEventEnum.SOLD && l.transactionHash === log.transactionHash),
  );
}

export async function requestMarketSync(chainId: number): Promise<number> {
  const url = `/sync/market?chainId=${encodeURIComponent(chainId)}`;

  const { data } = await publicApi.post<number>(url);

  return data;
}

export async function requestTokenSync(chainId: number, contractAddress: string): Promise<number> {
  const url = `/sync/nfts?chainId=${encodeURIComponent(chainId)}&tokenAddress=${encodeURIComponent(contractAddress)}`;

  const { data } = await publicApi.post<number>(url);

  return data;
}

export async function requestSync(chainId: number, contractAddress: string): Promise<void> {
  await Promise.all([
    requestMarketSync(chainId),
    requestTokenSync(chainId, contractAddress),
  ]);
}
