import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentWallet } from './wallet';
import {
  Container,
  Content, CreateButton,
  Logo,
  Menu,
  MenuNavLink,
  MenuNavText
} from './styles';

import logo from '../../assets/images/logo.svg';
import { CurrentChain } from './chain';

export function Header(): ReactElement {
  const history = useHistory();

  return (
    <>
      <Container>
        <Content>
          <Logo to="/">
            <img src={logo} alt="Smyowl" />
          </Logo>
          <Menu>
            <MenuLink to="/" exact>Início</MenuLink>
            <MenuLink to="/collections" exact>Explorar</MenuLink>
            <CreateButton onClick={() => history.push('/me/tokens/create')}>Criar</CreateButton>
            <CurrentWallet />
          </Menu>
        </Content>
      </Container>
      <CurrentChain />
    </>
  );
}

function MenuLink(props: { to: string, exact?: boolean, children: string }): ReactElement {
  return (
    <MenuNavLink to={props.to} exact={props.exact}>
      <MenuNavText>{props.children}</MenuNavText>
    </MenuNavLink>
  );
}
