import styled from '@emotion/styled';
import { Button } from '../Button';
import { LabeledText } from '../LabeledText';
import { HeartButton } from '../HeartButton';
import { css } from '@emotion/react';
import { NFTDisplay } from '../NFTDisplay';

export const Container = styled.div<{ isPlaceholder: boolean }>`
  background: #fff;
  box-shadow: 0 1px 14px #123A774D;
  border-radius: 0.75em;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  padding-bottom: 0.75rem;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.05);
  }
  
  ${props => props.isPlaceholder && css`opacity: 0.5; cursor: default;`}
`;

export const ImageContainer = styled.div`
  width: auto;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
`;

export const DisplayObject = styled(NFTDisplay)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
`;

export const LabeledNFTText = styled(LabeledText)`
  margin-left: 0.75rem;
  margin-right: 0.75rem;
`;

export const PriceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${Button} {
    padding: 0.8em 2em;
    font-size: 1em;
    margin: 0.75rem 0.75rem 0;
  }
`;

export const FloatingHeartButton = styled(HeartButton)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
`;
