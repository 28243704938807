import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
import { BlockchainCurrency, findChain } from '../../services/chains';
import { CurrencySelect } from './styles';

export interface CurrencyInputProps {
  currency: string;
  acceptEther?: boolean;
  chainId?: number;
  onChangeCurrency: (currency: string) => void;
}

export function CurrencyInput({ currency, acceptEther, chainId, onChangeCurrency }: CurrencyInputProps): ReactElement {

  const onChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => onChangeCurrency(e.target.value),
    [onChangeCurrency],
  );

  const currencies = useMemo(() => {
    const chain = findChain(chainId);
    let currencies: BlockchainCurrency[] = chain.currencies || [];

    if (!acceptEther) {
      currencies = currencies.filter(currency => currency.address && currency.address !== ethers.constants.AddressZero);
    }

    return currencies;
  }, [chainId, acceptEther]);

  useEffect(() => {
    if (!currencies || currencies.length === 0)
      return;

    if (!!currencies.find(c => c.address === currency))
      return;

    onChangeCurrency(currencies[0].address);
  }, [currency, currencies, onChangeCurrency]);

  return (
    <CurrencySelect value={currency} onChange={onChangeSelect}>
      {currencies.map((item, i) =>
        <option value={item.address} title={item.name} key={i}>{item.symbol}</option>
      )}
    </CurrencySelect>
  );
}
