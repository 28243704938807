import { BigNumberish, ethers, Signer, Contract, BigNumber } from 'ethers';
import { environment } from '../environments/environment';
import { NftProxy } from '../models/proxies/nft.proxy';
import { NftMarketItemProxy } from '../models/proxies/nft-market-item.proxy';
import { CreateListingPayload } from '../models/payloads/create-listing.payload';
import { isNftApproved, setNftApprovalForAll } from './nft';
import { findChain, isChainSupported } from './chains';
import useWeb3 from '../store/useWeb3';
import { api, publicApi } from './api';
import { requestMarketSync } from './logs';

export function getMarketContract(signer: Signer, chainId: number): Contract {
  const chain = findChain(chainId);

  if (!isChainSupported(chain) || !chain.contracts?.market)
    throw new Error(`A rede conectada (${chain.name}) não é suportada`);

  return new Contract(chain.contracts.market, environment.blockchain.marketplace.abi, signer);
}

async function createListing(contract: Contract, nft: NftProxy, payload: CreateListingPayload): Promise<string> {
  console.log(payload);

  const transaction: ethers.ContractTransaction = await contract.functions.create(
    nft.contractAddress,
    nft.id,
    payload.method,
    payload.currency,
    payload.price,
    payload.minimumPrice,
    payload.expiresAt,
  );

  console.log(transaction);

  const receipt = await transaction.wait();
  const event = receipt.events.find(event => event.event === 'MarketItemCreated');

  console.log(event);

  return event.args.itemId.toString();
}

async function removeListing(contract: Contract, marketItemId: BigNumberish): Promise<void> {
  const transaction: ethers.ContractTransaction = await contract.remove(marketItemId);

  await transaction.wait();
}

async function buy(contract: Contract, marketItemId: BigNumberish, currency: string, price: BigNumberish): Promise<string> {
  const isEth = !currency || currency === ethers.constants.AddressZero;

  const transaction: ethers.ContractTransaction = await contract.buy(marketItemId, price, { value: isEth ? price : 0 });

  const receipt = await transaction.wait();
  const event = receipt.events.find(event => event.event === 'MarketItemSold');

  return receipt.transactionHash;
}

export async function createMarketItem(nft: NftProxy, payload: CreateListingPayload): Promise<Partial<NftMarketItemProxy>> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível vender uma NFT sem conectar a carteira');

  if (nft.contractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(nft.contractChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const operator = contract.address;

  const isApproved = await isNftApproved(signer, nft.contractAddress, nft.id, address, operator, nft.contract?.isERC721);

  if (!isApproved)
    await setNftApprovalForAll(signer, nft.contractAddress, operator, true);

  const itemId = await createListing(contract, nft, payload);

  // Atualiza o item no cache
  return await refreshMarketItem(chainId, itemId)
    .catch(() => ({
      id: itemId,
      tokenContractAddress: nft.contractAddress,
      tokenContractChainId: nft.contractChainId,
      tokenId: nft.id,
      price: payload.price.toString(),
      buyerAddress: nft.ownerAddress,
      sellerAddress: address,
      isActive: true,
    }));
}

export async function removeMarketItem(itemChainId: number, itemId: BigNumberish): Promise<void> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível remover uma NFT sem conectar a carteira');

  if (itemChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(itemChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  await removeListing(contract, itemId);
}

export async function buyMarketItem(item: NftMarketItemProxy, price: BigNumberish): Promise<string> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível comprar uma NFT sem conectar a carteira');

  if (item.tokenContractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(item.tokenContractChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const transactionHash = await buy(contract, item.id, item.currency, price);

  return transactionHash;
}

export async function getMarketHighlights(limit: number): Promise<NftMarketItemProxy[]> {
  const { data } = await publicApi.get<NftMarketItemProxy[]>(`/market/items/highlights?limit=${limit}`);

  return data;
}

export async function refreshMarketItem(chainId: number, itemId: BigNumberish): Promise<NftMarketItemProxy | undefined> {
  const { data } = await api.post<NftMarketItemProxy | undefined>(`/networks/${encodeURIComponent(chainId)}/market/items/${encodeURIComponent(itemId.toString())}/refresh`);

  return data;
}

export async function setMarketplaceFees(listingPercentage: number, royaltiesPercentage: number): Promise<void> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível realizar essa ação sem conectar a uma carteira');

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const transaction: ethers.ContractTransaction = await contract.setFees(
    address,
    Math.round(listingPercentage * 10000),
    Math.round(royaltiesPercentage * 10000)
  );

  const receipt = await transaction.wait();

  console.log(receipt);
}

export async function getCurrentPrice(itemChainId: number, marketItemId: string): Promise<string> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível realizar essa ação sem conectar a uma carteira');

  if (itemChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(itemChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const price = await contract.callStatic.getPrice(marketItemId);

  return price.toString();
}

export async function getPriceComposition(contractChainId: number, contractAddress: string, tokenId: string, total: BigNumberish): Promise<{ sellerAmount: BigNumber, listingAmount: BigNumber, royaltyAmount: BigNumber }> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível realizar essa ação sem conectar a uma carteira');

  if (contractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(contractChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const composition = await contract.callStatic.getPriceComposition(contractAddress, tokenId, total);

  return {
    sellerAmount: BigNumber.from(composition.sellerAmount),
    listingAmount: BigNumber.from(composition.listingAmount),
    royaltyAmount: BigNumber.from(composition.royaltyAmount),
  };
}

export async function getMintInfo(contractChainId: number, contractAddress: string): Promise<{ maxAmount: string, price: string }> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível realizar essa ação sem conectar a uma carteira');

  if (contractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(contractChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const info = await contract.callStatic.getMintInfo(contractAddress);

  return {
    maxAmount: info.maxAmount.toString(),
    price: info.price.toString(),
  };
}

export async function buyMint(contractChainId: number, contractAddress: string, amount: number): Promise<{ contractAddress: string, tokenId: string }[]> {
  const { provider, address, chainId } = useWeb3.getState();

  if (!provider || !address)
    throw new Error('Não é possível realizar essa ação sem conectar a uma carteira');

  if (contractChainId !== chainId)
    throw new Error('Você deve estar conectado na rede ' + findChain(contractChainId).name);

  const signer = provider.getSigner(address);
  const contract = getMarketContract(signer, chainId);

  const transaction: ethers.ContractTransaction = await contract.functions.mint(contractAddress, amount);
  const receipt = await transaction.wait();

  const transfers = receipt.events.filter(event => event.event === 'Transfer');

  return transfers.map(event => {
    const tokenId: BigNumber = event.args.tokenId;

    return {
      contractAddress: contract.address.toLowerCase(),
      tokenId: tokenId?.toString(),
    };
  }).filter(tokens => !!tokens.tokenId);
}
