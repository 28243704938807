import { ReactElement } from 'react';
import { Container, Copyright, Grid, HorizontalRule, Links, Logo, Paragraph, Title } from './styles';

import logo from '../../assets/images/logo-negative.svg';
import { ContentContainer } from '../ContentContainer';

export function Footer(): ReactElement {
  return (
    <Container>
      <ContentContainer>
        <Grid>
          <div>
            <Logo src={logo} alt="Smyowl" />
            <Paragraph></Paragraph>
            <Title>Nossas redes sociais</Title>
          </div>
          <div>
            <Title>Marketplace</Title>
            <Links>
              <li><a href="#">Todos os NFTs</a></li>
              <li><a href="#">Coleções</a></li>
            </Links>
          </div>
          <div>
            <Title>Minha Conta</Title>
            <Links>
              <li><a href="#">Perfil</a></li>
              <li><a href="#">Favoritos</a></li>
              <li><a href="#">Watchlist</a></li>
              <li><a href="#">Minha Coleção</a></li>
              <li><a href="#">Configurações</a></li>
            </Links>
          </div>
          <div>
            <Title>Smyowl</Title>
            <Links>
              <li><a href="#">Sobre</a></li>
              <li><a href="#">Contato</a></li>
            </Links>
          </div>
          <div>
            <Title>Fique por dentro</Title>
            <Paragraph>Inscreva-se para receber e-mails sobre novidades e novas artes.</Paragraph>
          </div>
        </Grid>
        <HorizontalRule />
        <Copyright>
          <Paragraph>Copyrights © 2022 Smyowl - Todos os direitos reservados.</Paragraph>
          <Links>
            <li><a href="#">Política de Privacidade</a></li>
            <li><a href="#">Termos de Uso</a></li>
          </Links>
        </Copyright>
      </ContentContainer>
    </Container>
  );
}
