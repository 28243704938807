import { ReactElement, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { formatAddress } from '../../utils/functions';
import { createErrorNotification } from '../../services/notification';
import useAuth from '../../store/useAuth';
import useWeb3 from '../../store/useWeb3';

import {
  WalletDropdownButton,
  WalletAddress,
  WalletButton,
  WalletContainer,
  WalletDropdownContainer,
  WalletDropdownIcon,
  WalletText
} from './styles';

import dropdownIcon from '../../assets/images/arrow-down.svg';

export function CurrentWallet(): ReactElement {
  const address = useAuth(s => s.address);
  const isLogged = useAuth(s => s.isLogged);
  const connect = useWeb3(s => s.connect);
  const signIn = useAuth(s => s.signIn);
  const disconnect = useAuth(s => s.resetState);
  const history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);

  const strippedAddress = useMemo(() => formatAddress(address), [address]);

  useEffect(() => {
    if (!isOpen)
      return;

    const onClick = () => setOpen(false);

    window.addEventListener('click', onClick);

    return () => window.removeEventListener('click', onClick);
  }, [isOpen]);

  function onConnect(e: MouseEvent) {
    console.log('connect');
    e.preventDefault();

    setLoading(true);

    connect()
      .then(() => signIn())
      .catch(err => createErrorNotification(err))
      .finally(() => setLoading(false));
  }

  if (!isLogged) {
    return (
      <WalletContainer>
        <WalletText>
          <Spin spinning={isLoading}>
            <WalletButton onClick={onConnect}>Conectar Carteira</WalletButton>
          </Spin>
        </WalletText>
      </WalletContainer>
    );
  }

  return (
    <WalletContainer>

      <WalletText onClick={() => setOpen(!isOpen)}>
        <WalletAddress>{strippedAddress}</WalletAddress>
        <WalletDropdownIcon src={dropdownIcon} alt="Mais" isOpen={isOpen} />
      </WalletText>

      <WalletDropdownContainer isOpen={isOpen}>
        <WalletDropdownButton onClick={() => history.push('/me/collections/create')}>Criar Coleção</WalletDropdownButton>
        <WalletDropdownButton onClick={() => history.push('/me/tokens')}>Minhas NFTs</WalletDropdownButton>
        <WalletDropdownButton onClick={() => history.push('/me/collections')}>Minhas Coleções</WalletDropdownButton>
        <WalletDropdownButton onClick={() => disconnect()}>Desconectar</WalletDropdownButton>
      </WalletDropdownContainer>
    </WalletContainer>
  );
}
