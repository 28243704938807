import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';

import { NftProxy } from '../../../../models/proxies/nft.proxy';
import { SaleMethodEnum } from '../../../../models/enums/sale-method.enum';
import { MethodSelectorInput } from './methods';
import { TextInput } from '../../../../components/TextInput';
import { PriceInput } from '../../../../components/PriceInput';
import { CheckboxInput } from '../../../../components/CheckboxInput';
import { findChain } from '../../../../services/chains';
import { requestSync } from '../../../../services/logs';
import { createErrorNotification, createSuccessNotification } from '../../../../services/notification';
import { SellRates } from './rates';
import { SellButton } from './styles';
import { createMarketItemFromForm, isFormValid } from './utils';

export interface NftSellPageProps {
  nft: NftProxy;
  onUpdate?: () => void;
}

export function NftSellPage({ nft, onUpdate }: NftSellPageProps): ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [method, setMethod] = useState<SaleMethodEnum>(SaleMethodEnum.FIXED_PRICE);

  const [endAt, setEndAt] = useState<string>(() => moment().add(3, 'week').format('YYYY-MM-DD\\THH:00'));
  const [price, setPrice] = useState<string>(undefined);
  const [minPrice, setMinPrice] = useState<string>(undefined);
  const [currency, setCurrency] = useState<string>('');
  const [acceptBids, setAcceptBids] = useState<boolean>(false);

  const isValid = useMemo(
    () => isFormValid(method, endAt, price, minPrice, currency, acceptBids),
    [method, endAt, price, minPrice, currency, acceptBids],
  );

  const onSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      setLoading(true);

      createMarketItemFromForm(nft, method, endAt, price, minPrice, currency, acceptBids)
        .then(() => createSuccessNotification('NFT anunciada a venda com sucesso!'))
        .then(() => requestSync(nft.contractChainId, nft.contractAddress).catch(() => undefined))
        .then(() => onUpdate())
        .catch(err => createErrorNotification(err))
        .finally(() => setLoading(false));
    },
    [nft, onUpdate, method, endAt, price, minPrice, currency, acceptBids]
  );

  if (nft.currentMarketItem && nft.currentMarketItem.isActive)
    return <Redirect to={`/tokens/${findChain(nft.contractChainId).slug}/${encodeURIComponent(nft.contractAddress)}/${encodeURIComponent(nft.id)}`} />;

  const isDutchAuction = method === SaleMethodEnum.DUTCH_AUCTION;
  const isEnglishAuction = method === SaleMethodEnum.ENGLISH_AUCTION;
  const isFixedPrice = method === SaleMethodEnum.FIXED_PRICE;

  return (
    <div>
      <Spin spinning={isLoading}>
        <MethodSelectorInput label="Tipo de venda" onChange={setMethod} value={method} />

        <TextInput label="Data de encerramento" type="datetime-local" onChange={setEndAt} value={endAt} />

        {isDutchAuction && (
          <PriceInput label="Valor inicial" placeholder="Insira o valor inicial do item"
                      chainId={nft.contractChainId}
                      currency={currency} onChangeCurrency={setCurrency}
                      value={price} onChangeValue={setPrice} />
        )}

        {isEnglishAuction && (
          <PriceInput label="Lance inicial" placeholder="Insira o lance inicial do item"
                      chainId={nft.contractChainId}
                      currency={currency} onChangeCurrency={setCurrency}
                      value={price} onChangeValue={setPrice} />
        )}

        {isFixedPrice && (
          <PriceInput label="Valor" placeholder="Insira o valor do item"
                      chainId={nft.contractChainId}
                      currency={currency} onChangeCurrency={setCurrency} acceptEther={!acceptBids}
                      value={price} onChangeValue={setPrice} />
        )}

        {isFixedPrice && (
          <CheckboxInput label="Aceitar lances?" text="Permitir que compradores façam ofertas menores que o valor de venda"
                         value={acceptBids} onChange={setAcceptBids} />
        )}

        {isDutchAuction && (
          <PriceInput label="Menor valor" placeholder="Insira o valor da queda"
                      chainId={nft.contractChainId}
                      currency={currency} onChangeCurrency={setCurrency}
                      value={minPrice} onChangeValue={setMinPrice} />
        )}

        {isFixedPrice && acceptBids && (
          <PriceInput label="Lance mínimo" placeholder="Insira o menor lance que um comprador pode oferecer"
                      chainId={nft.contractChainId}
                      currency={currency} onChangeCurrency={setCurrency}
                      value={minPrice} onChangeValue={setMinPrice} />
        )}

        <SellRates nft={nft} />

        <SellButton onClick={onSubmit} disabled={!isValid}>Publicar para venda</SellButton>
      </Spin>
    </div>
  );
}
