import React, { ReactElement, useCallback } from 'react';
import { Label, LabelText } from '../Label';
import { Checkbox, CheckboxContainer } from './styles';

export interface CheckboxInputProps {
  label: string;
  text: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export function CheckboxInput({ label, text, value, onChange }: CheckboxInputProps): ReactElement {
  const onCheck = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked), [onChange]);

  return (
    <Label>
      <LabelText>{label}</LabelText>

      <CheckboxContainer>
        <Checkbox type="checkbox" checked={value} onChange={onCheck} />
        <span>{text}</span>
      </CheckboxContainer>

    </Label>
  );
}
