import { BigNumber, BigNumberish, ethers } from 'ethers';
import { NftMarketItemProxy } from '../models/proxies/nft-market-item.proxy';
import { SaleMethodEnum } from '../models/enums/sale-method.enum';
import { BlockchainCurrency, findChain } from '../services/chains';

import etherIcon from '../assets/images/eth.svg';
import wrappedEtherIcon from '../assets/images/weth.svg';
import etherTestnetIcon from '../assets/images/eth-testnet.svg';

export function formatPrice(price: BigNumberish, currency?: string, chainId?: number): [string, string | undefined, BlockchainCurrency | undefined] {
  if (!price)
    return ['-', undefined, undefined];

  const chain = findChain(chainId);
  const info = chain.currencies?.find(c => c.address.toLowerCase() === currency);
  const symbol = info?.symbol || '';

  const value = ethers.utils.formatUnits(price, 18);

  // Ether
  if (!currency || currency === ethers.constants.AddressZero || symbol === 'ETH')
    return [value, chain.testnet ? etherTestnetIcon : etherIcon, info];

  // Wrapped Ether
  if (symbol === 'WETH')
    return [value, wrappedEtherIcon, info];

  return [value, undefined, info];
}

export function canBid(item: NftMarketItemProxy | undefined, address: string): boolean {
  return item && address !== item.sellerAddress && doesAcceptBids(item);
}

export function doesAcceptBids(item: NftMarketItemProxy): boolean {
  if (!item.isActive)
    return false;

  if (item.method === SaleMethodEnum.ENGLISH_AUCTION || item.method === SaleMethodEnum.DUTCH_AUCTION)
    return true;

  return item.currency !== ethers.constants.AddressZero && BigNumber.from(item.minimumPrice).lt(item.price);
}

export function getMethodName(method: SaleMethodEnum): string {
  if (method === SaleMethodEnum.FIXED_PRICE)
    return 'Preço fixo';

  if (method === SaleMethodEnum.ENGLISH_AUCTION)
    return 'Venda para o lance maior';

  if (method === SaleMethodEnum.DUTCH_AUCTION)
    return 'Venda com preço em queda';

  return '';
}
