import { ReactElement, useMemo } from 'react';
import { formatTokenId } from '../../utils/functions';
import { NftProxy } from '../../models/proxies/nft.proxy';
import { getTokenTypeName } from '../../utils/metadata';
import { findChain } from '../../services/chains';
import { Details, DetailsContent, DetailsDictItem } from '../Details';
import { UserLink } from '../UserLink';
import { AddressLink } from '../ContractLink';

export interface TokenDetailsBoxProps {
  nft: NftProxy;
}

export function TokenDetailsBox({ nft }: TokenDetailsBoxProps): ReactElement {
  const tokenId = useMemo(() => formatTokenId(nft.id), [nft.id]);
  const chain = useMemo(() => findChain(nft.contractChainId), [nft.contractChainId]);

  const hasRoyalties = !!(nft.royaltyPercent && nft.royaltyReceiverAddress);

  return (
    <Details title="Detalhes">
      <DetailsContent>
        <DetailsDictItem label="Endereço do contrato">
          <AddressLink address={nft.contractAddress} chainId={nft.contractChainId} />
        </DetailsDictItem>

        <DetailsDictItem label="ID do Token">
          <a title={nft.id} href={nft.tokenUri} target="_blank" rel="noreferrer">
            {tokenId}
          </a>
        </DetailsDictItem>

        <DetailsDictItem label="Tipo do Token">
          {getTokenTypeName(nft.contract)}
        </DetailsDictItem>

        <DetailsDictItem label="Blockchain">
          {chain?.name || 'Não Identificada'}
        </DetailsDictItem>

        <DetailsDictItem label="Metadata">
          {nft.isMetadataCentralized ? 'Centralizada' : 'Decentralizada'}
        </DetailsDictItem>

        {hasRoyalties && (
          <DetailsDictItem label="Royalties">
            {(nft.royaltyPercent * 100).toLocaleString(['pt-BR'], { maximumFractionDigits: 6, minimumFractionDigits: 0 })}%
          </DetailsDictItem>
        )}

        <DetailsDictItem label="Proprietário Atual">
          <UserLink address={nft.ownerAddress} user={nft.owner} chainId={nft.contractChainId} />
        </DetailsDictItem>
      </DetailsContent>
    </Details>
  );
}
