import { CollectionProxy } from '../models/proxies/collection.proxy';
import { GetManyDefaultResponseProxy } from '../models/proxies/base/get-many-default-response.proxy';
import { createCrudUrl, CrudRequestParams } from '../utils/crud';
import { CreateCollectionPayload } from '../models/payloads/create-collection.payload';
import { UpdateCollectionPayload } from '../models/payloads/update-collection.payload';
import { CreateCollectionItemPayload } from '../models/payloads/create-collection-item.payload';
import { api, publicApi } from './api';

export async function getCollections(params: CrudRequestParams<CollectionProxy>): Promise<GetManyDefaultResponseProxy<CollectionProxy>> {
  const url = createCrudUrl('/collections', {
    ...params,
    join: [
      'items',
      'items.token',
    ],
  });

  const { data } = await publicApi.get<GetManyDefaultResponseProxy<CollectionProxy>>(url);

  return data;
}

export async function getCollection(slug: string): Promise<CollectionProxy> {
  const url = `/collections/${encodeURIComponent(slug)}?join[0]=items&join[1]=items.token`;

  const { data } = await publicApi.get<CollectionProxy>(url);

  return data;
}

export async function createCollection(payload: CreateCollectionPayload): Promise<CollectionProxy> {
  const url = '/collections';

  const { data } = await api.post<CollectionProxy>(url, payload);

  return data;
}

export async function updateCollection(slug: string, payload: UpdateCollectionPayload): Promise<CollectionProxy> {
  const url = `/collections/${encodeURIComponent(slug)}`;

  const { data } = await api.put<CollectionProxy>(url, payload);

  return data;
}

export async function addTokenToCollection(payload: CreateCollectionItemPayload): Promise<void> {
  const url = '/collections/items';

  await api.post(url, payload);
}

export async function removeTokenFromCollection(id: number): Promise<void> {
  const url = `/collections/items/${id}`;

  await api.delete(url);
}
