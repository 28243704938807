import { ReactElement, useState } from 'react';
import { NFTDisplayImage, NFTDisplayImageProps } from './image';
import { DisplayAudio, DisplayAudioContainer } from './styles';

export interface NFTDisplayAudioProps extends NFTDisplayImageProps {
  fallbackImage: string;
}

export function NFTDisplayAudio({ className, src, alt, fallbackImage }: NFTDisplayAudioProps): ReactElement {
  const [shouldFallback, setShouldFallback] = useState<boolean>(false);

  if (shouldFallback)
    return <NFTDisplayImage src={fallbackImage} alt={alt} className={className} />;

  return (
    <DisplayAudioContainer className={className}>
      <NFTDisplayImage src={fallbackImage} alt={alt} className={className} />
      <DisplayAudio
        src={src}
        aria-label={alt}
        controls
        onError={() => setShouldFallback(true)}
      />
    </DisplayAudioContainer>
  );
}
