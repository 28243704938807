import styled from '@emotion/styled';
import { Link, NavLink } from 'react-router-dom';
import { css } from '@emotion/react';
import { Button, SecondaryButton } from '../Button';

export const Container = styled.header`
  background: #fff;
  box-shadow: 0 3px 6px #121C6124;
  padding: 0 0.25rem;
  height: 6.25rem;
  
  position: relative;
  z-index: 10;
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const Logo = styled(Link)`
  font-size: 2rem;
  font-weight: bold;
  margin: 1.5rem 0;
  color: #007EC2;
  letter-spacing: -0.05em;
  display: flex;
  
  img {
    max-height: 3rem;
  }
`;

export const Menu = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const MenuNavText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const MenuNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 0.75rem;
  padding: 1rem;
  font-weight: normal;
  text-align: center;
  color: #979797;
  
  &:hover, &.active {
    color: #19191D;
  }
`;

export const CreateButton = styled(Button)`
  margin: 0 0.5em;
`;

export const WalletContainer = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0 0.75rem;
  
  position: relative;
  user-select: none;
`;

export const WalletText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WalletButton = styled(SecondaryButton)`
  font-size: 1rem;
  margin: 0 0.5em;
`;

export const WalletAddress = styled.div`
  color: #707070;
  margin: 0 0.5em;
`;

export const WalletDropdownIcon = styled.img<{ isOpen: boolean }>`
  height: 0.5em;
  transition: all 0.2s ease-in-out;

  ${props => props.isOpen && 'transform: rotate(180deg);'}
`;

export const WalletDropdownContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 50%;
  bottom: 0.3rem;
  z-index: 90;
  width: 150%;
  transform: translate(50%, 100%);
  
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5rem;

  padding: 0.2rem 0;
  transition: all 0.2s ease-in-out;

  pointer-events: none;
  opacity: 0;
  
  ${props => props.isOpen && css`
    bottom: -0.5rem;
    pointer-events: all;
    opacity: 1;
  `}
`;

export const WalletDropdownButton = styled.a`
  display: block;
  color: #707070;
  padding: 0.4rem 1rem;
`;

export const InvalidBlockchain = styled.div`
  font-size: 0.8rem;
  text-align: center;
  padding: 0.75rem;

  background: #FCFCFC;
  border-bottom: 0.05rem solid #D9D9D9;
`;
