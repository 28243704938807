import { ReactElement, MouseEvent, useState, useEffect, BaseSyntheticEvent, SyntheticEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { PageTitle } from '../../../components/PageTitle';
import { Button } from '../../../components/Button';
import { ContentContainer } from '../../../components/ContentContainer';
import { createNft } from '../../../services/nft';
import { createErrorNotification, createSuccessNotification } from '../../../services/notification';
import { CreateNftPayload } from '../../../models/payloads/create-nft.payload';
import { TextAreaInput, TextInput } from '../../../components/TextInput';
import { createCollection, getCollection, updateCollection } from '../../../services/collections';
import { CreateCollectionPayload } from '../../../models/payloads/create-collection.payload';
import { UpdateCollectionPayload } from '../../../models/payloads/update-collection.payload';

const initialCollection: CreateCollectionPayload = {
  title: '',
  description: '',
};

export function CollectionEditPage(): ReactElement {
  const { id } = useParams<{ id: string }>();

  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [payload, setPayload] = useState<CreateCollectionPayload>(initialCollection);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!id)
      return;

    setLoading(true);

    getCollection(id)
      .then(collection => {
        setPayload(collection);
        setUpdate(true);
      })
      .catch(error => createErrorNotification(error))
      .finally(() => setLoading(false));
  }, [id]);

  function setPayloadField<T>(field: keyof CreateCollectionPayload, value: T) {
    setPayload(payload => ({ ...payload, [field]: value }));
  }

  function onSubmit(e: SyntheticEvent) {
    e.preventDefault();

    setLoading(true);

    (isUpdate ? updateCollection(id, payload) : createCollection(payload))
      .then((collection) => {
        createSuccessNotification(`Coleção ${isUpdate ? 'atualizada' : 'criada'} com sucesso!`);
        history.push(`/collections/${encodeURIComponent(collection.slug)}`);
      })
      .catch(err => createErrorNotification(err))
      .finally(() => setLoading(false));
  }

  return (
    <ContentContainer>
      <Spin spinning={loading}>
        <PageTitle center>{isUpdate ? 'Atualizar Coleção' : 'Criar Coleção'}</PageTitle>
        <form onSubmit={onSubmit}>
          <TextInput label="Título"
                     value={payload.title} onChange={val => setPayloadField('title', val)} />
          <TextAreaInput label="Descrição"
                         value={payload.description} onChange={val => setPayloadField('description', val)} />
          <TextInput label="Site" placeholder="https://exemplo.com"
                     value={payload.siteUrl} onChange={val => setPayloadField('siteUrl', val)} />
          <TextInput label="Discord" placeholder="https://discord.gg/exemplo"
                     value={payload.discordUrl} onChange={val => setPayloadField('discordUrl', val)} />
          <TextInput label="Instagram" placeholder="https://instagram.com/exemplo"
                     value={payload.instagramUrl} onChange={val => setPayloadField('instagramUrl', val)} />
          <TextInput label="Twitter" placeholder="https://twitter.com/exemplo"
                     value={payload.twitterUrl} onChange={val => setPayloadField('twitterUrl', val)} />
          <TextInput label="Email" placeholder="exemplo@exemplo.com"
                     value={payload.email} onChange={val => setPayloadField('email', val)} />

          <Button onClick={onSubmit}>{isUpdate ? 'Atualizar Coleção' : 'Criar Coleção'}</Button>
        </form>
      </Spin>
    </ContentContainer>
  );
}

