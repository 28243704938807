import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import moment from 'moment/moment';
import { NftMarketItemBidProxy } from '../../../../models/proxies/nft-market-item-bid.proxy';
import { NftProxy } from '../../../../models/proxies/nft.proxy';
import { Details, DetailsContent, DetailsTable } from '../../../../components/Details';
import { createErrorNotification, createSuccessNotification } from '../../../../services/notification';
import { acceptBid, getBids, removeBid } from '../../../../services/bids';
import { SecondaryButton } from '../../../../components/Button';
import { CreateBidModal } from '../../../../components/CreateBidModal';
import { NFTPrice } from '../../../../components/NFTPrice';
import { UserLink } from '../../../../components/UserLink';
import { BidActionButton } from './styles';
import useWeb3 from '../../../../store/useWeb3';
import { NftMarketItemProxy } from '../../../../models/proxies/nft-market-item.proxy';
import { requestMarketSync, requestSync } from '../../../../services/logs';
import { canBid } from '../../../../utils/market';

export interface BidHistoryProps {
  nft: NftProxy;
  onUpdate: () => void;
}

export function BidHistory({ nft, onUpdate }: BidHistoryProps): ReactElement {
  const address = useWeb3(s => s.address);
  const [bids, setBids] = useState<NftMarketItemBidProxy[]>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const userCanBid = useMemo(() => canBid(nft.currentMarketItem, address), [address, nft.currentMarketItem]);

  useEffect(() => {
    if (!nft.contractAddress || !nft.id || !nft.currentMarketItem)
      return;

    setLoading(true);

    getBids(nft.contractChainId, nft.currentMarketItem?.id)
      .then(logs => setBids(logs))
      .catch(error => createErrorNotification(error))
      .finally(() => setLoading(false));
  }, [nft]);

  if (!nft.currentMarketItem)
    return <div />;

  return (
    <Details title="Histórico de Lances">
      <Spin spinning={isLoading}>
        <DetailsTable>
          <thead>
          <tr>
            <th>Valor</th>
            <th>Data</th>
            <th>Por</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          {bids?.map((bid, i) =>
            <BidItem bid={bid} market={nft.currentMarketItem} key={i} onUpdate={onUpdate} />
          )}
          </tbody>
        </DetailsTable>
      </Spin>

      {userCanBid && (
        <DetailsContent>
          <SecondaryButton onClick={() => setModalVisible(true)}>Novo Lance</SecondaryButton>

          <CreateBidModal
            market={nft.currentMarketItem}
            visible={isModalVisible}
            onDone={() => {
              setModalVisible(false);
              onUpdate();
            }}
            onCancel={() => setModalVisible(false)}
          />
        </DetailsContent>
      )}
    </Details>
  );
}

interface BidItemProps {
  bid: NftMarketItemBidProxy;
  market: NftMarketItemProxy;
  onUpdate: () => void;
}

function BidItem({ bid, market, onUpdate }: BidItemProps): ReactElement {
  return (
    <tr>
      <td><NFTPrice price={bid.value} currency={bid.currency || market.currency} chainId={bid.marketChainId} /></td>
      <td>{moment(bid.createdAt).format('DD/MM/YYYY')}</td>
      <td><UserLink address={bid.bidderAddress} chainId={bid.marketChainId} user={bid.bidder} /></td>
      <BidActions bid={bid} market={market} onUpdate={onUpdate} />
    </tr>
  );
}

function BidActions({ bid, market, onUpdate }: BidItemProps): ReactElement {
  const address = useWeb3(s => s.address);
  const [isLoading, setLoading] = useState<boolean>(false);

  function onRemove(e: React.MouseEvent) {
    e.preventDefault();

    setLoading(true);

    removeBid(market)
      .then(() => createSuccessNotification('O lance foi removido com sucesso.'))
      .then(() => requestMarketSync(market.tokenContractChainId))
      .then(() => onUpdate())
      .catch(err => createErrorNotification(err))
      .finally(() => setLoading(false));
  }

  function onAccept(e: React.MouseEvent) {
    e.preventDefault();

    setLoading(true);

    acceptBid(market, bid.bidderAddress, bid.value)
      .then(() => createSuccessNotification('O lance foi aceito com sucesso.'))
      .then(() => requestSync(market.tokenContractChainId, market.tokenContractAddress))
      .then(() => onUpdate())
      .catch(err => createErrorNotification(err))
      .finally(() => setLoading(false));
  }

  return (
    <td>
      <Spin spinning={isLoading}>
        {bid.isActive && address === bid.bidderAddress && (
          <BidActionButton onClick={onRemove}>Remover</BidActionButton>
        )}
        {bid.isActive && market && address === market.sellerAddress && (
          <BidActionButton onClick={onAccept}>Aceitar</BidActionButton>
        )}
      </Spin>
    </td>
  )
}
