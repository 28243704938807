import { ReactElement } from 'react';
import { Container, Link } from './styles';

import websiteIcon from '../../assets/images/icon-website.svg';
import discordIcon from '../../assets/images/icon-discord.svg';
import twitterIcon from '../../assets/images/icon-twitter.svg';
import instagramIcon from '../../assets/images/icon-instagram.svg';
import emailIcon from '../../assets/images/icon-email.svg';

export interface SocialIconsProps {
  siteUrl?: string;
  discordUrl?: string;
  twitterUrl?: string;
  instagramUrl?: string;
  email?: string;

  className?: string;
}

export function SocialIcons(props: SocialIconsProps): ReactElement {
  return (
    <Container>
      {props.siteUrl && <SocialIcon src={websiteIcon} alt="Site" url={props.siteUrl} />}
      {props.discordUrl && <SocialIcon src={discordIcon} alt="Discord" url={props.discordUrl} />}
      {props.twitterUrl && <SocialIcon src={twitterIcon} alt="Twitter" url={props.twitterUrl} />}
      {props.instagramUrl && <SocialIcon src={instagramIcon} alt="Instagram" url={props.instagramUrl} />}
      {props.email && <SocialIcon src={emailIcon} alt="Email" url={'mailto:' + props.email} />}
    </Container>
  );
}

function SocialIcon(props: { src: string, url: string, alt: string }): ReactElement {
  return (
    <Link href={props.url} title={props.alt}>
      <img src={props.src} alt={props.alt} />
    </Link>
  )
}
