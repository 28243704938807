import styled from '@emotion/styled';


export const Container = styled.span`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  max-height: 1em;
  margin-right: 0.2em;
`;
