import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Modal, Spin } from 'antd';
import { NftMarketItemProxy } from '../../models/proxies/nft-market-item.proxy';
import { TextInput } from '../TextInput';
import { ethers } from 'ethers';
import { CreateButton, ModalContainer } from './styles';
import { SaleMethodEnum } from '../../models/enums/sale-method.enum';
import { createBid } from '../../services/bids';
import { createErrorNotification, createSuccessNotification } from '../../services/notification';
import { NftMarketItemBidProxy } from '../../models/proxies/nft-market-item-bid.proxy';
import { requestMarketSync } from '../../services/logs';
import { parseUnits } from '../../utils/ethers';

export interface CreateBidModalProps {
  market: NftMarketItemProxy;
  visible: boolean;
  onDone: () => void;
  onCancel: () => void;
}

export function CreateBidModal(props: CreateBidModalProps): ReactElement {

  return <Modal visible={props.visible}
                onCancel={props.onCancel}
                modalRender={() => (
                  <CreateBidModalContent {...props} />
                )} />;
}

function CreateBidModalContent({ market, onDone }: CreateBidModalProps): ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [value, setValue] = useState<string>(
    () => ethers.utils.formatUnits(market.highestBid || market.minimumPrice || 0, 18),
  );

  const minPrice = useMemo(
    () => ethers.utils.formatUnits(market.minimumPrice, 18),
    [market.minimumPrice],
  );

  const maxPrice = useMemo(
    () => {
      if (market.method === SaleMethodEnum.ENGLISH_AUCTION)
        return undefined;

      return ethers.utils.formatUnits(market.price, 18);
    },
    [market.method, market.price],
  );

  const onSubmit = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    setLoading(true);

    createBid(market, parseUnits(value, 'valor do lance'))
      .then(() => createSuccessNotification('Lance criado com sucesso!'))
      .then(() => requestMarketSync(market.tokenContractChainId))
      .then(() => onDone())
      .catch(error => createErrorNotification(error))
      .finally(() => setLoading(false));
  }, [market, onDone, value]);

  return (
    <ModalContainer>
      <Spin spinning={isLoading}>
        <TextInput label="Valor do Lance" value={value} onChange={setValue} type="number"
                   step={0.001} min={minPrice} max={maxPrice} />

        <CreateButton onClick={onSubmit}>Criar Lance</CreateButton>
      </Spin>
    </ModalContainer>
  );
}
