import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const PageTitle = styled.h1<{ center?: boolean }>`
  font-size: 3rem;
  font-weight: bold;
  margin: 3rem 0;
  color: #19191D;
  
  ${props => props.center && css`text-align: center;`}


  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
