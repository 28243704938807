import { ReactElement, useRef } from 'react';
import { DisplayImage } from './styles';

import backgroundImage from '../../assets/images/background.png';

export interface NFTDisplayImageProps {
  src: string;
  alt: string;
  className?: string;
  onClick?: () => void;
}

export function NFTDisplayImage(props: NFTDisplayImageProps): ReactElement {
  const ref = useRef<HTMLImageElement>();

  return (
    <DisplayImage
      ref={ref}
      src={props.src || backgroundImage}
      alt={props.alt}
      className={props.className}
      onClick={props.onClick}
      onError={() => {
        if (ref?.current)
          ref.current.src = backgroundImage;
      }}
    />
  );
}
