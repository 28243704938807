import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    colors: {

    };
  }
}

export const theme: Theme = {
  colors: {},
};
