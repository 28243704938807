import styled from '@emotion/styled';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  display: block;
  margin-right: 0.5rem !important;
  border-radius: 0.5rem !important;
  
  transition: all 0.1s ease-in-out;
  
  &:checked {
    background: #faad14;
    border-color: #faad14;
  }
`;
