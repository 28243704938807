import { ReactElement, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container, DisplayObject,
  FloatingHeartButton,
  ImageContainer,
  LabeledNFTText,
  PriceInfo
} from './styles';
import { NftProxy } from '../../models/proxies/nft.proxy';
import { NFTPrice } from '../NFTPrice';
import { cleanTokenMetadata } from '../../utils/metadata';
import { findChain } from '../../services/chains';

export interface NFTCardProps {
  nft: NftProxy;
  className?: string;
}

export function NFTCard(props: NFTCardProps): ReactElement {
  /*const [isLoved, setLoved] = useState<boolean>(false);*/
  const history = useHistory();

  const nft = props.nft;
  const market = nft.currentMarketItem;
  const metadata = useMemo(() => cleanTokenMetadata(nft), [nft]);

  function open() {
    const chain = findChain(nft.contractChainId);

    history.push(`/tokens/${chain.slug}/${encodeURIComponent(nft.contractAddress)}/${encodeURIComponent(nft.id)}`);
  }

  return (
    <Container className={props.className} isPlaceholder={nft.isPlaceholder} onClick={open}>
      <ImageContainer>
        <DisplayObject metadata={metadata} onClick={open} rich={false} />
        {/*<FloatingHeartButton hasLove={isLoved} onToggle={() => setLoved(!isLoved)} />*/}
      </ImageContainer>

      <LabeledNFTText label="Token" onClick={open}>{metadata.name}</LabeledNFTText>

      {market && (
        <PriceInfo>
          <LabeledNFTText label="Preço">
            <NFTPrice price={market?.price} currency={market?.currency} chainId={market?.tokenContractChainId} />
          </LabeledNFTText>
        </PriceInfo>
      )}
    </Container>
  );
}
