import { ReactElement, useMemo } from 'react';
import {
  Container,
  ContainerPlaceholder,
  Content,
  CoverContainer,
  ImageContainerObject,
  Paragraph,
  Title
} from './styles';
import { CollectionProxy } from '../../models/proxies/collection.proxy';
import backgroundImage from '../../assets/images/background.png';

export interface CollectionCardProps {
  collection: CollectionProxy;
}

export function CollectionCard({ collection }: CollectionCardProps): ReactElement {
  const shortDescription = useMemo(() => collection.description.trim().split('\n')[0], [collection.description]);

  const ContainerElem = collection.isPlaceholder ? ContainerPlaceholder : Container;

  return (
    <ContainerElem to={'/collections/' + encodeURIComponent(collection.slug)}>
      <CoverContainer src={collection.coverUrl || backgroundImage} />
      <ImageContainerObject src={collection.pictureUrl || backgroundImage} />
      <Content>
        <Title>{collection.title}</Title>
        <Paragraph>{shortDescription}</Paragraph>
      </Content>
    </ContainerElem>
  );
}
