import { GetState, SetState } from 'zustand';
import { ethers } from 'ethers';
import moment from 'moment/moment';

import { defaultQueryClient } from '../../App';
import { publicApi } from '../../services/api';
import { UseAuthStore } from './models';
import { createSignMessage } from './utils';
import { TokenProxy } from '../../models/proxies/token.proxy';
import useWeb3 from '../useWeb3';

/**
 * Realiza autenticação conforme o EIP-4361
 *
 * @see https://eips.ethereum.org/EIPS/eip-4361
 */
async function erc4361(address: string, provider: ethers.providers.Web3Provider): Promise<TokenProxy> {
  const signer = provider.getSigner(address);

  const chainId = await signer.getChainId();

  const message = createSignMessage(address, chainId);
  const signature = await signer.signMessage(message);

  const { data: token } = await publicApi.post<TokenProxy>('/auth/erc4361', {address, message, signature});

  return token;
}

/**
 * Realiza autenticação conforme o EIP-4361
 *
 * @see https://eips.ethereum.org/EIPS/eip-4361
 */
export function signIn(set: SetState<UseAuthStore>): UseAuthStore['signIn'] {
  return async function() {
    const { addresses, provider } = useWeb3.getState();

    if (!addresses || !provider || addresses.length === 0)
      throw new Error('Não conectado a blockchain.');

    const address = addresses[0];
    const token = await erc4361(address, provider);

    set({
      isLogged: true,
      address,
      token,
    });
  };
}

export function getToken(set: SetState<UseAuthStore>, get: GetState<UseAuthStore>): UseAuthStore['getToken'] {
  return async function() {
    const state = get();

    if (state.token && moment(state.token.expiresAt).subtract(15, 'seconds').isAfter(moment()))
      return state.token;

    const web3 = useWeb3.getState();

    let address: string | undefined = web3.address;
    let provider: ethers.providers.Web3Provider | undefined = web3.provider;

    if (!address || !provider) {
      const result = await web3.connect();

      address = result.address;
      provider = result.provider;
    }

    const token = await erc4361(address, provider);

    set({
      isLogged: true,
      address,
      token,
    });

    return token;
  };
}

export function resetState(set: SetState<UseAuthStore>): () => void {
  return function () {
    defaultQueryClient.clear();

    set({
      isLogged: false,
      token: undefined,
      address: undefined,
    });
  };
}
