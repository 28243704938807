import { ReactElement, useEffect, useLayoutEffect, useState } from 'react';
import moment, { MomentInput } from 'moment/moment';

export interface CountdownProps {
  timestamp: MomentInput;
}

export function Countdown(props: CountdownProps): ReactElement {
  const [currentTime, setCurrentTime] = useState<string>();

  useLayoutEffect(() => {
    const finalTime = moment(props.timestamp);

    const timer = setInterval(() => {
      const seconds = finalTime.diff(moment(), 'seconds');

      setCurrentTime(formatTimeStringFromSeconds(seconds));
    }, 1000);

    return () => clearInterval(timer);
  }, [props.timestamp]);

  return (
    <span>{currentTime}</span>
  );
}

function formatTimeStringFromSeconds(seconds: number): string {
  const str: string[] = [];
  const hasHours = seconds >= 3600;
  const hasMinutes = seconds >= 60;
  const hasSeconds = seconds > 0;

  const formatPlural = (time: number, word: string) => time + ' ' + word + (time === 1 ? '' : 's');

  if (hasHours) {
    str.push(formatPlural(Math.floor(seconds / 3600), 'hora'));
    seconds %= 3600;
  }

  if (hasMinutes) {
    str.push(formatPlural(Math.floor(seconds / 60), 'minuto'));
    seconds %= 60;
  }

  if (hasSeconds) {
    str.push(formatPlural(Math.floor(seconds), 'segundo'));
  }

  return str.join(' ');
}
