import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import moment from 'moment/moment';

import { NftLogProxy } from '../../../../models/proxies/nft-log.proxy';
import { getNftLogs, mergeRedundantNftLogs, requestTokenSync } from '../../../../services/logs';
import { NftProxy } from '../../../../models/proxies/nft.proxy';
import { createErrorNotification } from '../../../../services/notification';
import { getLogAction } from '../../../../utils/logs';
import { Details, DetailsTable } from '../../../../components/Details';
import { TransactionLink } from '../../../../components/TransactionLink';
import { UserLink } from '../../../../components/UserLink';
import { NFTPrice } from '../../../../components/NFTPrice';

export interface TransactionHistoryProps {
  nft: NftProxy;
}

export function TransactionHistory({ nft }: TransactionHistoryProps): ReactElement {
  const [logs, setLogs] = useState<NftLogProxy[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!nft.contractAddress || !nft.id)
      return;

    setLoading(true);

    requestTokenSync(nft.contractChainId, nft.contractAddress)
      .catch(() => undefined)
      .then(() => getNftLogs(nft.contractChainId, nft.contractAddress, nft.id))
      .then(logs => setLogs(mergeRedundantNftLogs(logs)))
      .catch(error => createErrorNotification(error))
      .finally(() => setLoading(false));
  }, [nft]);

  return (
    <Details title="Atividades do Item">
      <Spin spinning={isLoading}>
        <DetailsTable>
          <thead>
          <tr>
            <th>Evento</th>
            <th>Valor</th>
            <th>De</th>
            <th>Para</th>
            <th>Data</th>
          </tr>
          </thead>

          <tbody>
          {logs?.map((log, i) =>
            <HistoryItem log={log} key={i} />
          )}
          </tbody>
        </DetailsTable>
      </Spin>
    </Details>
  );
}

function HistoryItem({ log }: { log: NftLogProxy }): ReactElement {
  const [action, icon] = useMemo(() => getLogAction(log), [log]);
  const occurredDate = useMemo(() => moment(log.occurredAt).format('DD/MM/YYYY'), [log.occurredAt]);

  return (
    <tr>
      <td>{action}</td>
      <td>
        <NFTPrice price={log.value} currency={log.currency} chainId={log.tokenContractChainId} />
      </td>
      <td>
        <UserLink address={log.fromAddress} user={log.from} chainId={log.tokenContractChainId} />
      </td>
      <td>
        <UserLink address={log.toAddress} user={log.to} chainId={log.tokenContractChainId} />
      </td>
      <td>
        <TransactionLink transactionHash={log.transactionHash} chainId={log.tokenContractChainId}>
          {occurredDate}
        </TransactionLink>
      </td>
    </tr>
  );
}
