import moment from 'moment';
import { NftMetadataStruct } from '../models/structs/nft-metadata.struct';
import { NftProxy } from '../models/proxies/nft.proxy';
import { ContractProxy } from '../models/proxies/contract.proxy';
import { ensureArray, ensureObject, formatTokenId } from './functions';
import { NftMetadataAttributeStruct } from '../models/structs/nft-metadata-attribute.struct';
import { environment } from '../environments/environment';

export function cleanTokenMetadata(token: NftProxy): NftMetadataStruct {
  const data = ensureObject(token.metadata) || {};

  return {
    ...data,
    name: data.name?.toString().trim().substring(0, environment.limits.maxNftTitle) || `${token.contract?.name || 'Token'} #${formatTokenId(token.id)}`,
    description: data.description?.toString().trim().substring(0, environment.limits.maxNftDescription) || '',
    attributes: (ensureArray(data.attributes) || []).filter(a => !!a.trait_type && !!a.value),
  };
}

export function getTokenTypeName(contract: ContractProxy | undefined): string {
  if (contract?.isERC721)
    return 'ERC-721';

  if (contract?.isERC1155)
    return 'ERC-1155';

  return 'Não Identificado';
}

export function formatAttributeValue(value: string | number, displayType?: 'number' | 'date' | string): string {
  if (typeof value === 'number') {
    if (displayType === 'date')
      return moment.unix(value).format('DD/MM/YYYY');

    return value.toLocaleString('pt-BR');
  }

  return value?.toString();
}

export function formatAttribute(attribute: NftMetadataAttributeStruct): string {
  const value = formatAttributeValue(attribute.value, attribute.display_type);

  if (attribute.max_value)
    return `${value} / ${formatAttributeValue(attribute.max_value, attribute.display_type)}`;

  return value;
}
