import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { Button } from '../Button';

import background from '../../assets/images/background.png';
import highlightUnderline from '../../assets/images/highlight-underline.svg';

export const IntroContainer = styled.div`
  background: linear-gradient(180deg, transparent 90%, #fff 100%), url(${background}) no-repeat center center;
  background-size: cover;
`;

export const IntroColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 6.25rem);
  max-width: 1400px;
  margin: 0 auto;
  
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const increasePerspective = keyframes`
  from {
    perspective: 25rem;
  }
  
  to {
    perspective: 40rem;
  }
`;

export const FloatingNFTContainer = styled.div`
  min-width: 40rem;
  max-width: 40vw;
  height: 100%;
  font-size: 120%;
  perspective: 40rem;
  perspective-origin: center center;
  position: relative;
  animation: ${increasePerspective} 4s ease-in-out;

  @media (max-width: 800px) {
    min-height: 40rem;
    margin: 0 auto;
    min-width: 5rem;
    max-width: 80vw;
    font-size: 100%;
  }
`;

const float = keyframes`
  from {
    transform: translateY(0);
  }
  
  to {
    transform: translateY(1rem);
  }
`;

export const FloatingCard1 = styled.div`
  width: 50%;
  transform: translate(-90%, -40%) rotateX(6deg) rotateY(13deg) rotateZ(-7deg) scale(0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  
  > * {
    animation: ${float} 6s ease-in-out infinite alternate;
    animation-delay: 2s;
  }
`;

export const FloatingCard2 = styled.div`
  width: 50%;
  transform: translate(-10%, -60%) rotateX(5deg) rotateY(-13deg) rotateZ(5deg) scale(1.01);
  position: absolute;
  top: 50%;
  left: 50%;

  > * {
    animation: ${float} 6s ease-in-out infinite alternate-reverse;
  }
`;

export const LandingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LandingTextContent = styled.div`
  width: 40rem;
  margin: 1rem;
`;

export const LandingTextTitle = styled.h4`
  color: #F7941D;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const LandingTextHeading = styled.h2`
  color: #393941;
  font-size: 3rem;
  font-weight: 600;
  line-height: 4.25rem;
`;

export const LandingTextHeadingHighlight = styled.strong`
  color: #F7941D;
  position: relative;
  
  :after {
    display: block;
    content: '';
    height: 2rem;
    position: absolute;
    bottom: -1rem;
    left: -20%;
    width: 140%;
    background: url(${highlightUnderline}) top center no-repeat;
    background-size: 100% 2rem;
  }
`;

export const LandingTextParagraph = styled.p`
  color: #777777;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 3rem 0;
`;

export const LandingTextButton = styled(Button)`
  min-width: 10rem;
`;
