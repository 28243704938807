import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { ContentContainer } from '../../../components/ContentContainer';
import { NftProxy } from '../../../models/proxies/nft.proxy';
import { createErrorNotification } from '../../../services/notification';
import { cleanTokenMetadata } from '../../../utils/metadata';
import { getNft } from '../../../services/nft';
import { findChainBySlug } from '../../../services/chains';
import { NFTInfo } from '../../../components/NFTInfo';
import { NftInfoPage } from './InfoPage';
import { Columns } from './styles';

import background from '../../../assets/images/background.png';
import { NftSellPage } from './SellPage';

const placeholderNft: NftProxy = {
  id: '',
  contractAddress: '',
  contractChainId: 0,
  ownerAddress: '',
  metadata: {
    name: '...',
    image: background,
  },
  isMetadataCentralized: false,
};

export function NftViewPage(): ReactElement {
  const { chainSlug, address, id } = useParams<{ chainSlug: string, address: string, id: string }>();
  const history = useHistory();

  const chain = useMemo(() => findChainBySlug(chainSlug), [chainSlug]);
  const [nft, setNft] = useState<NftProxy>(placeholderNft);
  const [isLoading, setLoading] = useState<boolean>(false);

  const chainId = chain?.chainId;

  function onFetch() {
    setLoading(true);

    getNft(chainId, address, id)
      .then(nft => setNft(nft))
      .catch(err => createErrorNotification(err))
      .finally(() => setLoading(false));
  }

  useEffect(onFetch, [chainId, address, id]);

  const metadata = useMemo(() => cleanTokenMetadata(nft), [nft]);

  return (
    <ContentContainer>
      <Spin spinning={isLoading}>
        <Columns>

          <NFTInfo nft={nft} metadata={metadata} />

          <Switch>
            <Route path="/tokens/:chainSlug/:address/:id/sell">
              <NftSellPage nft={nft} onUpdate={onFetch} />
            </Route>
            <Route>
              <NftInfoPage nft={nft} metadata={metadata} onUpdate={onFetch}
                           onSell={() => history.push(generatePath('/tokens/:chainSlug/:address/:id/sell', { chainSlug, address, id }))} />
            </Route>
          </Switch>

        </Columns>
      </Spin>
    </ContentContainer>
  );
}
