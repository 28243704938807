import styled from '@emotion/styled';
import { Rows } from '../Rows';
import { css } from '@emotion/react';

export const Box = styled.div`
  background: #fff;
  border: 0.05rem solid #D9D9D9;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #404040;
  padding: 0;
  margin-top: 2rem;
  
  overflow-y: auto;
  
  ${Rows} {
    align-items: center;
  }
`;

export const Container = styled.div<{ closed: boolean }>`
  overflow-y: auto;
  max-height: 20rem;
  
  transition: all 0.15s ease-in-out;
  
  ${props => props.closed && css`
    overflow-y: hidden;
    max-height: 0;
  `}
`;

export const Title = styled.strong<{ closed: boolean }>`
  display: block;
  color: #19191D;
  background: #FCFCFC;
  border-bottom: ${props => props.closed ? '0' : '0.05rem'} solid #DEDBDB;
  padding: 1rem 1.25rem;
  margin: 0;
  user-select: none;
`;

export const Content = styled.div`
  margin: 1.25rem;
  
  p {
    max-width: 50vw;
    word-wrap:break-word;
  }
`;

export const Dict = styled(Rows)`
  margin-top: 1rem;
`;

export const Value = styled.span`
  color: #979797;
  
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Table = styled.table`
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
  
  thead th {
    font-weight: 400;
  }
  
  tbody td {
    background: #FCFCFC;
    font-weight: 500;
  }
  
  th, td {
    border-bottom: 0.05rem solid #DEDBDB;
    padding: 1rem 1.25rem;
  }
  
  tr:last-child td {
    border-bottom-width: 0;
  }
`;
