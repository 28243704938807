import { ReactElement, useMemo } from 'react';
import { NftMetadataStruct } from '../../models/structs/nft-metadata.struct';
import { NftMetadataAttributeStruct } from '../../models/structs/nft-metadata-attribute.struct';
import { Details, DetailsContent, DetailsDictItem } from '../Details';
import { formatAttribute, formatAttributeValue } from '../../utils/metadata';
import { AttributeCard, AttributeCardTitle, AttributeCardValue, AttributeGrid } from './styles';

export interface TokenAttributesBoxProps {
  metadata: NftMetadataStruct;
}

export function TokenAttributesBox({ metadata }: TokenAttributesBoxProps): ReactElement {
  const data = metadata && Array.isArray(metadata.attributes) ? metadata.attributes : undefined;

  const attribs = useMemo(() => data?.filter(a => !a.max_value), [data]);
  const stats = useMemo(() => data?.filter(a => !!a.max_value), [data]);

  return (
    <>
      <AttributesDict attributes={attribs} />
      <StatsDict attributes={stats} />
    </>
  );
}

interface AttributesDictProps {
  attributes: NftMetadataAttributeStruct[];
}

function AttributesDict({ attributes }: AttributesDictProps): ReactElement {
  if (!attributes || !Array.isArray(attributes) || attributes.length === 0)
    return <div />;

  return (
    <Details title="Atributos">
      <DetailsContent>
        <AttributeGrid>
          {attributes.map((metadata, i) => (
            <AttributeItem key={i} metadata={metadata} />
          ))}
        </AttributeGrid>
      </DetailsContent>
    </Details>
  );
}

function StatsDict({ attributes }: AttributesDictProps): ReactElement {
  if (!attributes || !Array.isArray(attributes) || attributes.length === 0)
    return <div />;

  return (
    <Details title="Estatísticas">
      <DetailsContent>
        {attributes.map((metadata, i) => (
          <StatsItem key={i} metadata={metadata} />
        ))}
      </DetailsContent>
    </Details>
  );
}

interface AttributeItemProps {
  metadata: NftMetadataAttributeStruct;
}

function AttributeItem({ metadata }: AttributeItemProps) {
  const value = useMemo(() => formatAttributeValue(metadata.value, metadata.display_type), [metadata.value, metadata.display_type]);

  return (
    <AttributeCard>
      <AttributeCardTitle>{metadata.trait_type}</AttributeCardTitle>
      <AttributeCardValue title={value}>{value}</AttributeCardValue>
    </AttributeCard>
  );
}

function StatsItem({ metadata }: AttributeItemProps) {
  const value = useMemo(() => formatAttribute(metadata), [metadata]);

  return (
    <DetailsDictItem label={metadata.trait_type} title={value}>
      {value}
    </DetailsDictItem>
  );
}
