import React, { ReactElement, useCallback } from 'react';
import { LabelDiv, LabelText } from '../Label';
import { Container } from './styles';
import { CurrencyInput } from './currency';

export interface PriceInputProps {
  label: string;
  placeholder?: string;

  value: string;
  currency: string;
  acceptEther?: boolean;
  chainId: number;

  onChangeValue: (value: string) => void;
  onChangeCurrency: (currency: string) => void;
}

export function PriceInput({ label, placeholder, value, currency, chainId, acceptEther, onChangeValue, onChangeCurrency }: PriceInputProps): ReactElement {
  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChangeValue(e.target.value),
    [onChangeValue],
  );

  return (
    <LabelDiv>
      <LabelText>{label}</LabelText>
      <Container>
        <CurrencyInput
          currency={currency}
          chainId={chainId}
          acceptEther={acceptEther}
          onChangeCurrency={onChangeCurrency}
        />
        <input value={value} onChange={onChangeInput} placeholder={placeholder} step={0.001} type="number" />
      </Container>
    </LabelDiv>
  );
}
