import React, { MouseEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { Button, SecondaryButton } from '../Button';
import { NftMarketItemProxy } from '../../models/proxies/nft-market-item.proxy';
import { buyMarketItem, removeMarketItem } from '../../services/market';
import { createErrorNotification, createSuccessNotification } from '../../services/notification';
import useWeb3 from '../../store/useWeb3';
import { findChain } from '../../services/chains';
import { CreateBidModal } from '../CreateBidModal';
import { SaleMethodEnum } from '../../models/enums/sale-method.enum';
import { canBid } from '../../utils/market';
import { requestMarketSync } from '../../services/logs';

export interface BuyNftButtonProps {
  item: NftMarketItemProxy;
  onUpdate?: () => void;
  className?: string;
  showRemoveButton?: boolean;
}

export function BuyNftButton(props: BuyNftButtonProps): ReactElement {
  const address = useWeb3(s => s.address);
  const chainId = useWeb3(s => s.chainId);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isBidOpen, setBidOpen] = useState<boolean>(false);

  const isSeller = props.item.sellerAddress === address;
  const isEnglishAuction = props.item.method === SaleMethodEnum.ENGLISH_AUCTION;

  const userCanBid = useMemo(() => isEnglishAuction && canBid(props.item, address), [isEnglishAuction, address, props.item]);

  const onBuy = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (!isSeller && isEnglishAuction) {
      return setBidOpen(true);
    }

    if (props.item.tokenContractChainId !== chainId) {
      return createErrorNotification(`Você está conectado a ${findChain(chainId).name} mas deve se conectar a ${findChain(props.item.tokenContractChainId).name} para realizar a transação.`);
    }

    setLoading(true);

    let promise: Promise<unknown>;

    if (isSeller) {
      promise = removeMarketItem(props.item.tokenContractChainId, props.item.id)
        .then(() => createSuccessNotification('NFT removida do mercado com sucesso!'));
    } else {
      promise = buyMarketItem(props.item, props.item.price)
        .then(transactionHash => createSuccessNotification(`NFT adquirida com sucesso! Hash da transação: ${transactionHash}`));
    }

    promise
      .then(() => requestMarketSync(props.item.tokenContractChainId))
      .then(() => props.onUpdate && props.onUpdate())
      .catch(err => createErrorNotification(err))
      .finally(() => setLoading(false));
  }, [props, isSeller, isEnglishAuction, chainId]);

  if (!props.showRemoveButton && isSeller)
    return <div />;

  const ButtonElem = isSeller ? SecondaryButton : Button;

  return (
    <Spin spinning={isLoading}>
      {(!isEnglishAuction || userCanBid || isSeller) && (
        <ButtonElem className={props.className} onClick={onBuy}>
          {isSeller ? 'Cancelar Anuncio' : (isEnglishAuction ? 'Novo Lance' : 'Comprar')}
        </ButtonElem>
      )}

      {isEnglishAuction && (
        <CreateBidModal
          market={props.item}
          visible={isBidOpen}
          onDone={() => {
            setBidOpen(false);
            props.onUpdate();
          }}
          onCancel={() => setBidOpen(false)}
        />
      )}
    </Spin>
  );
}
